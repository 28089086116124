import React, { useState, useEffect } from 'react';
import { useAuthContext } from "../context/AuthContext";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './MYcontracts.css';

const MyContracts = () => {
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global");
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContracts = async () => {
        if (user?.phone) {
            try {
                const getContractsUrl = process.env.REACT_APP_GET_CONTRACTS_URL;

                const response = await fetch(getContractsUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phone: user.phone }),
                });

                const data = await response.json();
                if (data.status === 'success') {
                    const now = new Date();
                    const contractsList = data.contracts
                        .map((contract, index) => {
                            const contractDate = new Date(contract.timestamp);
                            const ageInDays = (now - contractDate) / (1000 * 60 * 60 * 24);
                            return {
                                ...contract,
                                className: `MyContracts${index + 1}`,
                                timestamp: contractDate.toLocaleDateString() || 'N/A',
                                ageInDays: ageInDays,
                            };
                        })
                        .filter(contract => contract.ageInDays <= 2); // Filter for contracts not older than 2 days

                    setContracts(contractsList);
                } else {
                    console.error('Error fetching contracts:', data.message);
                }
            } catch (error) {
                console.error("Error fetching contracts:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    fetchContracts();
}, [user?.phone]);


  const copyToClipboard = (referenceNumber) => {
    navigator.clipboard.writeText(referenceNumber); // Use camelCase for referenceNumber
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p style={{ color: '#ffffff' }}>{t("MyContracts.loading")}</p> {/* White text for loading message */}
      </div>
    );
  }

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  return (
    <div className="my-contracts-container" dir={getDirection()}>
      <h1 className="my-contracts-title">{t('Home.quotes')}</h1>
      {contracts.length === 0 ? (
        <p className="no-contracts-message">{t("MyContracts.noContracts")}</p>
      ) : (
        <ul className="contracts-list">
          {contracts.map((contract, index) => (
            <li key={contract.id} className={`contract-item ${contract.className}`}>
              <div className="contract-details">
                <p className="contract-ref">
                  <strong>{t("MyContracts.reference_number")}:</strong> {contract.referenceNumber} {/* Use camelCase */}
                  <button
                    className="copy-button"
                    onClick={() => copyToClipboard(contract.referenceNumber)} 
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </p>
                <p className="contract-date">
                  <strong>{t("MyContracts.date")}:</strong> {contract.timestamp}
                </p>
                <p className="contract-name">
                  <strong>{t("MyContracts.name")}:</strong> {contract.name}
                </p>
              </div>
              <a href={contract.pdfUrl} target="_blank" rel="noopener noreferrer" className="download-link">
                {t("MyContracts.download")}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MyContracts;
