
import './Switch.css';
import Shop from "./Shop";
import React, { useState, useRef, useEffect } from "react";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from "react-router-dom";
import styled from "styled-components"; // Import 'styled' from 'styled-components'

const hoverEasing = "cubic-bezier(0.23, 1, 0.32, 1)";
const ReturnEasing = "cubic-bezier(0.445, 0.05, 0.55, 0.95)";

const CardWrap = styled.div`
  margin: 10px;
  transform: perspective(800px);
  transform-style: preserve-3d;
  cursor: pointer;
  background-color: #trasparent;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
     
  ); 
  &:hover {
    .card-info {
      transform: translateY(0);
    }
    .card-info p {
      opacity: 1;
      transition: 0.6s ${hoverEasing};
    }
    .card-info:after {
      transition: 5s ${hoverEasing};
      opacity: 1;
      transform: translateY(0);
    }

    .card-bg {
      transition: 
        0.6s ${hoverEasing},
        opacity 5s ${hoverEasing};
      opacity: 0.8;
    }
    .card {
      transition:
        0.6s ${hoverEasing},
        box-shadow 2s ${hoverEasing};
      box-shadow:
        rgba(255, 255, 255, 0.2) 0 0 40px 5px,
        rgba(255, 255, 255, 1) 0 0 0 1px,
        rgba(0, 0, 0, 0.66) 0 30px 60px 0,
        inset #333 0 0 0 5px;
    }
  }
`;

const StyledCard = styled.div`
  position: relative;
  flex: 0 0 258px ;
  width: 240px;
  height: 340px;
  background-color: #333;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.66) 0 30px 60px 0,
    inset #333 0 0 0 1px,
    inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
  transition: 1s ${ReturnEasing};
  background-repeat: no-repeat;  // Add this line
  background-size: cover;  // Adjust as needed
  background-position: center;  // Adjust as needed
`;


const CardBg = styled.div`

`;

const CardInfo = styled.div`
  padding: 20px;   



  pointer-events: none;
  position: absolute;
  bottom: 0;
  color: #fff;
  transform: translateY(40%);
  transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 1s  1.6s  ${ReturnEasing}, opacity 5s 1s ${ReturnEasing};

  p {
    opacity: 0;
    text-shadow: rgba(0, 0, 0, 1) 0 2px 3px;
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);

  }

  * {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 12) 100%
    );
    background-blend-mode: overlay;
    opacity: 12;
    transform:  (100%);
    transition: 5s 1s ${ReturnEasing};


    
  }
`;
const Button = styled.button`
  background-color: transparent;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:  5s 1s ${ReturnEasing};

  &:hover {
    background-color:   transparent;
  }
`;
const Card = ({ backgroundImage, children }) => {
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const [mouseLeaveDelay, setMouseLeaveDelay] = useState(null);
    const cardRef = useRef(null);
    
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (cardRef.current) {
                const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = cardRef.current;
                const x = e.pageX - offsetLeft - offsetWidth / 2;
                const y = e.pageY - offsetTop - offsetHeight / 2;
                setMouseX(x);
                setMouseY(y);
            }
        };
        const handleMouseEnter = () => {
            clearTimeout(mouseLeaveDelay);
        };
        const handleMouseLeave = () => {
            clearTimeout(mouseLeaveDelay);
            const delay = setTimeout(() => {
                setMouseX(0);
                setMouseY(0);
            }, 1000);
            setMouseLeaveDelay(delay);
        };
        if (cardRef.current) {
            cardRef.current.addEventListener("mousemove", handleMouseMove);
            cardRef.current.addEventListener("mouseenter", handleMouseEnter);
            cardRef.current.addEventListener("mouseleave", handleMouseLeave);
        }
        return () => {
            if (cardRef.current) {
                cardRef.current.removeEventListener("mousemove", handleMouseMove);
                cardRef.current.removeEventListener("mouseenter", handleMouseEnter);
                cardRef.current.removeEventListener("mouseleave", handleMouseLeave);
            }
        };
    }, [mouseLeaveDelay]);
    const mousePX = mouseX / (cardRef.current?.offsetWidth || 1);
    const mousePY = mouseY / (cardRef.current?.offsetHeight || 1);
    const cardStyle = {
        backgroundImage: `url(${backgroundImage})`,
        transform: `rotateY(${mousePX * 40}deg) rotateX(${-mousePY * 40}deg)`,
    };
    const cardBgTransform = {
        transform: `translateX(${-mousePX * 40}px) translateY(${-mousePY * 40}px)`, // Increase translation values
    };
    return (
        <CardWrap className="card-wrap" ref={cardRef}>
            <StyledCard className="card" style={cardStyle}>
                <CardBg className="CardBg" style={cardBgTransform}></CardBg>
                <CardInfo className="card-info">{children}</CardInfo>
            </StyledCard>
        </CardWrap>
    );
};
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
 
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
if (process.env.NODE_ENV === 'development') {
  console.log('Firebase App:', app);
}
const newsCollectionRef = collection(firestore, 'Cabel');
const CardGrid = ({ news }) => {
    const chunkedNews = news.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 1);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    return (
        <div class="grid-container">
            {chunkedNews.map((chunk, rowIndex) => (
                <div key={rowIndex} className="row">
                    {chunk.map((newsItem, colIndex) => (
                        <div key={colIndex} >
                            <Link to="/product" state={{ data: { newsItem } }}>
                                <Card backgroundImage={newsItem.image1}>
                              
                                </Card>
                            </Link>
                            <p>{newsItem.name}<br />{newsItem.Title}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
function Cabel() {
    const [news, loading, error] = useCollectionData(newsCollectionRef, { idField: 'id' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await getDocs(newsCollectionRef);
                const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log('Data from news collection:', data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const titleStyle = {
        fontSize: '2em',  // Adjust the size as needed
        color: '#333',    // Set your preferred text color
    };
    return (
        <div>
            <div >
                <Shop />
            </div>
            <div className="contact-us-container">
                <header>
                    <h1 style={titleStyle}>Cabel</h1>
                    <br />
                    <br />
                    <br />
                </header>

                <div>
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    {news && <CardGrid news={news} />}
                </div>
            </div>
        </div>

    );
}

export default Cabel;
