import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Contact_Us.css";
import back from '../components/images/back.png';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { AiOutlineTwitter, AiOutlineInstagram, AiFillFacebook } from 'react-icons/ai';
import icon1Img from '../components/images/icon6.png';
import icon2Img from '../components/images/icon7.png';
import icon3Img from '../components/images/icon8.png';

const Contact_Us = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const [openArticle, setOpenArticle] = useState(null);
    const [openSection, setOpenSection] = useState(null);

    const toggleSection = (section) => {
        setOpenSection(current => current === section ? null : section);
        setOpenArticle(null); // Close any open article when a section is toggled
    };

    const toggleArticle = (article) => {
        setOpenArticle(current => current === article ? null : article);
        setOpenSection(null); // Close any open section when an article is toggled
    };

    const AnimatedDiv = ({ isOpen, children }) => {
        const animationProps = useSpring({
            backgroundColor: isOpen ? '#F4F6FA' : 'transparent',
            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
        });

        return (
            <animated.div style={animationProps}>
                {children}
            </animated.div>
        );
    };

    const iconData = [
        {
            img: icon1Img,
            title: t("faq.ContactUtitle1"),
            text1: t("faq.Contacttxt11"),
            text2: t("faq.Contacttxt12"),
            MessagTitle: t("faq.MessagTitle1"),
        },
        {
            img: icon2Img,
            title: t("faq.ContactUtitle2"),
            text1: t("faq.Contacttxt21"),
            text2: t("faq.Contacttxt22"),
            MessagTitle: t("faq.MessagTitle2"),
        },
        {
            img: icon3Img,
            title: t("faq.ContactUtitle3"),
            text1: t("faq.Contacttxt31"),
            text2: t("faq.Contacttxt32"),
            MessagTitle: t("faq.MessagTitle3"),
        },
    ];

    return (
        <div className={getDirection() + " backgroundwhiteASS"} style={{ direction: getDirection() }}>
            <div className="bannersol" style={{ backgroundImage: `url(${back})` }}></div>

            <div className="bahite">
                <br />
                <div>
                    <p className="GoldCont">{t("faq.question")}</p>
                </div>
                <br />
                <div className="backgroundwhite" dir={getDirection(i18n.language)}>
                    <p className="normalcolapsTextCaption">{t("faq.adam")}</p>
                    <br />

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.authenticateContract"))} aria-expanded={openArticle === t("faq.authenticateContract")}>
                            <animated.span style={{ transform: openArticle === t("faq.authenticateContract") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.authenticateContract") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.authenticateContract")}</p>
                        </button>
                        {openArticle === t("faq.authenticateContract") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.authenticateContract")}>
                                <p>{t("faq.authenticateContractAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.authenticateContract") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.paymentMethods"))} aria-expanded={openArticle === t("faq.paymentMethods")}>
                            <animated.span style={{ transform: openArticle === t("faq.paymentMethods") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.paymentMethods") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.paymentMethods")}</p>
                        </button>
                        {openArticle === t("faq.paymentMethods") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.paymentMethods")}>
                                <p>{t("faq.paymentMethodsAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.paymentMethods") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.serviceScope"))} aria-expanded={openArticle === t("faq.serviceScope")}>
                            <animated.span style={{ transform: openArticle === t("faq.serviceScope") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.serviceScope") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.serviceScope")}</p>
                        </button>
                        {openArticle === t("faq.serviceScope") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.serviceScope")}>
                                <p>{t("faq.serviceScopeAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.serviceScope") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.installmentsAvailable"))} aria-expanded={openArticle === t("faq.installmentsAvailable")}>
                            <animated.span style={{ transform: openArticle === t("faq.installmentsAvailable") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.installmentsAvailable") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.installmentsAvailable")}</p>
                        </button>
                        {openArticle === t("faq.installmentsAvailable") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.installmentsAvailable")}>
                                <p>{t("faq.installmentsAvailableAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.installmentsAvailable") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.medicalServiceNotAvailable"))} aria-expanded={openArticle === t("faq.medicalServiceNotAvailable")}>
                            <animated.span style={{ transform: openArticle === t("faq.medicalServiceNotAvailable") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.medicalServiceNotAvailable") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.medicalServiceNotAvailable")}</p>
                        </button>
                        {openArticle === t("faq.medicalServiceNotAvailable") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.medicalServiceNotAvailable")}>
                                <p>{t("faq.medicalServiceNotAvailableAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.medicalServiceNotAvailable") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.howToSubmitComplaintOrFeedback"))} aria-expanded={openArticle === t("faq.howToSubmitComplaintOrFeedback")}>
                            <animated.span style={{ transform: openArticle === t("faq.howToSubmitComplaintOrFeedback") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.howToSubmitComplaintOrFeedback") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.howToSubmitComplaintOrFeedback")}</p>
                        </button>
                        {openArticle === t("faq.howToSubmitComplaintOrFeedback") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.howToSubmitComplaintOrFeedback")}>
                                <p>{t("faq.howToSubmitComplaintOrFeedbackAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.howToSubmitComplaintOrFeedback") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.canIChangeServiceProviderAfterServices"))} aria-expanded={openArticle === t("faq.canIChangeServiceProviderAfterServices")}>
                            <animated.span style={{ transform: openArticle === t("faq.canIChangeServiceProviderAfterServices") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.canIChangeServiceProviderAfterServices") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.canIChangeServiceProviderAfterServices")}</p>
                        </button>
                        {openArticle === t("faq.canIChangeServiceProviderAfterServices") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.canIChangeServiceProviderAfterServices")}>
                                <p>{t("faq.canIChangeServiceProviderAfterServicesAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.canIChangeServiceProviderAfterServices") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.canIRequestRefundForService"))} aria-expanded={openArticle === t("faq.canIRequestRefundForService")}>
                            <animated.span style={{ transform: openArticle === t("faq.canIRequestRefundForService") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.canIRequestRefundForService") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.canIRequestRefundForService")}</p>
                        </button>
                        {openArticle === t("faq.canIRequestRefundForService") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.canIRequestRefundForService")}>
                                <p>{t("faq.canIRequestRefundForServiceAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.canIRequestRefundForService") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.whatToDoIfThereIsSuddenBirth"))} aria-expanded={openArticle === t("faq.whatToDoIfThereIsSuddenBirth")}>
                            <animated.span style={{ transform: openArticle === t("faq.whatToDoIfThereIsSuddenBirth") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.whatToDoIfThereIsSuddenBirth") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.whatToDoIfThereIsSuddenBirth")}</p>
                        </button>
                        {openArticle === t("faq.whatToDoIfThereIsSuddenBirth") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.whatToDoIfThereIsSuddenBirth")}>
                                <p>{t("faq.whatToDoIfThereIsSuddenBirthAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.whatToDoIfThereIsSuddenBirth") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.areThereCriteriaForHospitals"))} aria-expanded={openArticle === t("faq.areThereCriteriaForHospitals")}>
                            <animated.span style={{ transform: openArticle === t("faq.areThereCriteriaForHospitals") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.areThereCriteriaForHospitals") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.areThereCriteriaForHospitals")}</p>
                        </button>
                        {openArticle === t("faq.areThereCriteriaForHospitals") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.areThereCriteriaForHospitals")}>
                                <p>{t("faq.areThereCriteriaForHospitalsAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.areThereCriteriaForHospitals") && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle(t("faq.whatArePersonalSupportServices"))} aria-expanded={openArticle === t("faq.whatArePersonalSupportServices")}>
                            <animated.span style={{ transform: openArticle === t("faq.whatArePersonalSupportServices") ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === t("faq.whatArePersonalSupportServices") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("faq.whatArePersonalSupportServices")}</p>
                        </button>
                        {openArticle === t("faq.whatArePersonalSupportServices") && (
                            <AnimatedDiv isOpen={openArticle === t("faq.whatArePersonalSupportServices")}>
                                <p>{t("faq.whatArePersonalSupportServicesAnswer")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== t("faq.whatArePersonalSupportServices") && <div className="line"></div>}
                    </div>

                    <div id="ContactU" className="ContactU">
                        <Link to="/SendMessage" state={{ MessagTitle: "اطرح سؤالا" }}>
                            <button className="blueNewTkhile">
                                {t("faq.sendyouropinion")}
                            </button>
                        </Link>
                        <br />
                        <p className="TitlContw">{t("faq.send")}</p>
                        <div className="cardsContactU">
                            {iconData.map((data, index) => (
                                <div key={index} className="cardContactU">
                                    <img src={data.img} alt={data.title} />
                                    <p className="TitleConta">{data.title}</p>
                                    <p className="textBCont">{data.text1}</p>
                                    <p className="textBCont">{data.text2}</p>
                                    <Link to="/SendMessage" state={{ MessagTitle: data.MessagTitle }}>
                                        <button className="sendbtn">
                                            {t("faq.send1")}
                                        </button>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    <br />

                    <div className="SocialM">
                        <p className="TitlContw">{t("faq.orsend")}</p>
                        <div className="socialIcons">
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <AiOutlineTwitter />
                            </a>
                            <a href="https://www.instagram.com/lahalak/" target="_blank" rel="noopener noreferrer">
                                <AiOutlineInstagram />
                            </a>
                            <a href="https://www.facebook.com/LahaLak" target="_blank" rel="noopener noreferrer">
                                <AiFillFacebook />
                            </a>
                        </div>
                    </div>

                    <br />
                    <br />
                    <div className="centered-section">
                        <h1 className="section-title">{t("faq.captionOur1")}</h1>
                        <p className="contact-info">{t("faq.callCenterContactPart1")}</p>
                        <p className="contact-info">{t("faq.callCenterContactPart2")}</p>
                        <p className="contact-info">{t("faq.finalCostInfo")}</p>
                    </div>

                    <div id="ourdata" className="contact-section">
                        <p className="contact-info">{t("faq.captiontwo1")}</p>
                        <p className="contact-info">{t("faq.captiontwo2")}</p>
                        <div className="contact-info">
                            <p className="contact-info">{t("faq.location1")}{t("faq.locationDescription1")}</p>
                        </div>
                        <div className="contact-info">
                            <p className="contact-info">{t("faq.location2")}{t("faq.locationDescription2")}</p>
                        </div>
                        <p className="contact-info">
                            {t("faq.Phone")}
                            <span className="contact-info"> 00966/126140488</span>
                        </p>
                        <p className="contact-info">
                            {t("faq.mail")}
                            <span className="contact-info"> info@lahalak.sa</span>
                        </p>
                        <p className="contact-info">
                            {t("faq.Registrationnumber")},{t("faq.CommercialRegistration")},{t("faq.tax")}
                        </p>
                        <p className="contact-info">{t("faq.maarouf")}</p>
                    </div>

                    <div>
                        <p className="contact-subtitle">{t("faq.joinUs")}</p>
                        <div className="button-section" dir={getDirection()}>
                            <p className="button-text">{t("faq.joinPhrase")}</p>
                            <Link to="/SendMessage" state={{ MessagTitle: "طلب انضمام" }}>
                                <button className="omarbuttons">
                                    {t("faq.joinUstwo")}
                                </button>
                            </Link>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default Contact_Us;
