import { Link } from 'react-router-dom';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div style={{ direction: getDirection(), backgroundColor: "white", padding: "20px", borderRadius: "8px" }}>
            <h1>{t("privacy_policy.title")}</h1>
            <div>
                <h2>{t("privacy_policy.section1")}</h2>
                <p>{t("privacy_policy.text1")}</p>
            </div>
            <div >
                <h2>{t("privacy_policy.section2")}</h2>
                <p>{t("privacy_policy.text2")}</p>
            </div>
            <div >
                <h2>{t("privacy_policy.section3")}</h2>
                <p>{t("privacy_policy.text3")}</p>
            </div>
            <div >
                <h2>{t("privacy_policy.section4")}</h2>
                <p>{t("privacy_policy.text4")}</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
