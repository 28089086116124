import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Article.css";
function Article() {
    const location = useLocation();
    const [newsItem, setNewsItem] = useState([]); 
    useEffect(() => {
        const { state } = location;
        if (state && state.data) {
            setNewsItem(state.data.newsItem);
        }
    }, [location]);
    return (
        <>
            <div className="container">
                <div className="article-container">
                    {newsItem && (
                        <div className="article">
                            <img className="article-image"
                                src={newsItem.image}
                                alt={newsItem.title} />
                            <div className="article-content">
                                <b>
                                    <p className="article-title">
                                        {newsItem.title}
                                    </p>
                                </b>
                                <p className="article-description">
                                    {newsItem.context}
                                </p>
                                <br />
                                <b>
                                    <p className="article-author">
                                        By: {newsItem.by}
                                    </p>
                                </b>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default Article;
