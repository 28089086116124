// import React, { useState, useEffect } from 'react';
// import './Solution.css';
// import { useTranslation } from 'react-i18next';
// import backgroundf from "../components/images/finger.svg";

// const Solution = () => {
//     const { t, i18n } = useTranslation("global");
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const getDirection = () => {
//         return i18n.language === "ar" ? "rtl" : "ltr";
//     };
//     const totalBubbles = 6;
//     const [visibleBubbles, setVisibleBubbles] = useState(0);
//     const [isChecked, setIsChecked] = useState(true); // Set initial state to true

//     const bubbleTexts = [
//         {
//             title: t("animationOn.title1"),
//             text: t("animationOn.text1")
//         },
//         {
//             title: t("animationOn.title2"),
//             text: t("animationOn.text2")
//         },
//         {
//             title: t("animationOn.title3"),
//             text: t("animationOn.text3")
//         },
//         {
//             title: t("animationOn.title4"),
//             text: t("animationOn.text4")
//         },
//         {
//             title: t("animationOn.title5"),
//             text: t("animationOn.text5")

//         },
//         {
//             title: t("animationOn.title6"),
//             text: t("animationOn.text6")
//         },
//     ];

//     const handleToggle = () => {
//         setIsChecked(!isChecked);
//     };

//     const toggleBubbles = () => {
//         if (visibleBubbles === 0) {
//             let count = 0;
//             const interval = setInterval(() => {
//                 setVisibleBubbles(current => current + 1);
//                 count += 1;
//                 if (count >= totalBubbles) clearInterval(interval);
//             }, 500);
//         } else {
//             setVisibleBubbles(0);
//         }
//     };

//     return (
//         <div className="animationOn" style={{ direction: getDirection() }}>
//             <button onClick={toggleBubbles} className='toggle-button'>
//                 <label className="t">
//                     <input
//                         className="t__checkbox"
//                         type="checkbox"
//                         checked={isChecked}
//                         onChange={handleToggle}
//                         value={isChecked ? "on" : "off"}
//                     />
//                     <div className="svg-container" style={{ width: '100%', maxWidth: '150px', height: 'auto' }}>
//                         <svg className="t__svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ backgroundImage: `url(${backgroundf})` }}>
//                             <ellipse className="t__svg-dot" cx="6" cy="6" rx="2" ry="1" fill="#072C7B" transform="rotate(-45,6,6)" />
//                             <circle className="t__svg-ring" cx="12" cy="12" r="6" fill="none" stroke="#072C7B" strokeWidth="2" strokeLinecap="round" strokeDasharray="0 5 27.7 5" strokeDashoffset="0.01" transform="rotate(-90,12,12)" />
//                             <line className="t__svg-line" x1="12" y1="6" x2="12" y2="15" stroke="#072C7B" strokeWidth="2" strokeLinecap="round" strokeDasharray="9 9" strokeDashoffset={isChecked ? -6 : 3} />
//                             <line className="t__svg-line" x1="12" y1="6" x2="12" y2="12" stroke="#072C7B" strokeWidth="2" strokeLinecap="round" strokeDasharray="6 6" strokeDashoffset={isChecked ? -3 : 6} />
//                         </svg>

//                     </div>

//                 </label>

//             </button>

            // <div className="text-bubbles">
            //     {bubbleTexts.map(({ title, text }, index) => (
            //         <div key={index} className={`bubble ${index < visibleBubbles ? 'visible' : ''}`}>
            //             <p className="bubble-title" style={{ direction: getDirection() }}>{title}</p>
            //             <p className="bubble-text" style={{ direction: getDirection() }}>{text}</p>
            //         </div>
            //     ))}
            // </div>
//         </div>
//     );
// };

// export default Solution;



import React, { useState, useEffect } from 'react';
import './Solution.css';
import { useTranslation } from 'react-i18next';
import backgroundf from "../components/images/finger.svg";

const Solution = () => {
    const { t, i18n } = useTranslation("global");

    // Scroll to top on component mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";
    
    const totalBubbles = 6;
    const [visibleBubbles, setVisibleBubbles] = useState(0);
    const [isChecked, setIsChecked] = useState(true);

    const bubbleTexts = [
        { title: t("animationOn.title1"), text: t("animationOn.text1") },
        { title: t("animationOn.title2"), text: t("animationOn.text2") },
        { title: t("animationOn.title3"), text: t("animationOn.text3") },
        { title: t("animationOn.title4"), text: t("animationOn.text4") },
        { title: t("animationOn.title5"), text: t("animationOn.text5") },
        { title: t("animationOn.title6"), text: t("animationOn.text6") },
    ];

    const handleToggle = () => setIsChecked(!isChecked);

    const toggleBubbles = () => {
        if (visibleBubbles === 0) {
            let count = 0;
            const interval = setInterval(() => {
                setVisibleBubbles(current => current + 1);
                count += 1;
                if (count >= totalBubbles) clearInterval(interval);
            }, 500);
        } else {
            setVisibleBubbles(0);
        }
    }; 

    return (
        <div className="animationOno" style={{ direction: getDirection() }}>
            <button onClick={toggleBubbles} className='toggle-button'>
                <div className="svg-container" style={{ width: '90%', maxWidth: '100px', height: 'auto' }}>
                    <svg className="t__svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <pattern id="bg" patternUnits="userSpaceOnUse" width="24" height="24">
                                <image href={backgroundf} x="0" y="0" width="24" height="24" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#bg)" />
                        <ellipse className="t__svg-dot" cx="6" cy="6" rx="2" ry="1" fill="#3498db" transform="rotate(-45,6,6)" />
                        <circle className="t__svg-ring" cx="12" cy="12" r="6" fill="none" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeDasharray="0 5 27.7 5" strokeDashoffset="0.01" transform="rotate(-90,12,12)" />
                        <line className="t__svg-line" x1="12" y1="6" x2="12" y2="15" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeDasharray="9 9" strokeDashoffset={isChecked ? -6 : 3} />
                        <line className="t__svg-line" x1="12" y1="6" x2="12" y2="12" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeDasharray="6 6" strokeDashoffset={isChecked ? -3 : 6} />
                    </svg>
                </div>
            </button>

            <div className="text-bubbles">
                {bubbleTexts.map(({ title, text }, index) => (
                    <div key={index} className={`bubble ${index < visibleBubbles ? 'visible' : ''}`}>
                        <p className="bubble-title">{title}</p>
                        <p className="bubble-text">{text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Solution;