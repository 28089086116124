import React, { useEffect } from 'react';

const VisitorLogger = () => {
  useEffect(() => {
    // Capture screen resolution and time zone
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const resolution = `${screenWidth}x${screenHeight}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Send the captured data to the server
    const data = new URLSearchParams();
    data.append('resolution', resolution);
    data.append('timezone', timezone);

    // Use the full URL to your PHP script
    fetch(process.env.REACT_APP_LOG_VISIT_URL, {
      method: 'POST',
      body: data,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
  })
  
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, []);

  return null; // This component does not render anything
};

export default VisitorLogger;
