import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon2Img from '../components/images/se.png';
import './Service_Details.css';

const ServiceAdd_Details = () => {
    const location = useLocation();
    const { id, price, optionName, hospitalName, hospitalLocation, image, bookingDetails, serviceDetails } = location.state || {};

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t, i18n } = useTranslation("global");
    const [additionalServices, setAdditionalServices] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
    const translationBaseUrl = process.env.REACT_APP_TRANSLATION_BASE_URL;

    const dataUrl = `${translationBaseUrl}/${i18n.language === "ar" ? "Ar" : "En"}/Add_on_services.json`;

    const response = await fetch(dataUrl);
    const data = await response.json();
    setAdditionalServices(data);
} catch (error) {
    setError('Failed to load service details.');
    console.error('Error loading data:', error);
} finally {
    setLoading(false);
}

        };
        loadData();
    }, [i18n.language]);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const conditionsSectionTitle = t("Details.specialConditions");
    const includedServicesSectionTitle = t("Details.includedServices");

    const service = additionalServices.find(service => service.id === id.toString());

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!service) return <p>Error: Service not found</p>;

    const conditionsSection = service.sections.find(section => section.title === conditionsSectionTitle);
    const includedServicesSection = service.sections.find(section => section.title === includedServicesSectionTitle);

    const renderContent = (content) => {
        if (Array.isArray(content)) {
            return content.map((item, index) => (
                <div className='titlecollapseeB' key={index}>
                    <p>{item.text || item}</p>
                </div>
            ));
        } else {
            return (
                <div className='titlecollapseeB'>
                    <img src={icon2Img} alt="Icon" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <p>{content}</p>
                </div>
            );
        }
    };

    return (
        <div className="backgroundwhiteASS" dir={getDirection(i18n.language)}>
            <br />
            <br />
            <br />
            <p className='TitlContwo' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {t("Details.sub")}
            </p>
            <br />
            <div>
                <br />
                <p className='titlecollapsee'>{t("Details.sub1")}</p>
                <div className='articleContent'>
                    <div className='det_gr'>
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub2")}</p>
                            <p>{hospitalName}</p>
                        </div>
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub3")}</p>
                            <p>{hospitalLocation}</p>
                        </div>
                        {optionName && (
                            <div className='gellule'>
                                <p className='titlenormalcolapsText'>{t("Details.sub4")}</p>
                                <p>{optionName}</p>
                            </div>
                        )}
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub5")}</p>
                            <p>{price}</p>
                        </div>
                    </div>
                </div>
                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.includedServices")}</p>
                </div>
                <br />
                <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} >
                    <ul>
                        {serviceDetails && serviceDetails.map((detail, index) => (
                            <li key={index} className="totire">
                                <img src={icon2Img} alt="Icon" />
                                <p>{detail}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.addedValues")}</p>
                </div>
                <br />
                <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} >
                    {bookingDetails && bookingDetails.map((detail, index) => (
                        <div key={index}>
                            {Object.entries(detail).map(([key, value]) => (
                                <ul key={key}>
                                    <li className="totire">
                                        <img src={icon2Img} alt="Icon" />
                                        <p>{value}</p>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    ))}
                </div>
                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.specialConditions")}</p>
                </div>
                <br />
                <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <ul style={{ padding: 0 }}>
                        {conditionsSection ? (
                            conditionsSection.content.map((condition, index) => (
                                <li className="circle-list-item" key={index}>
                                    {condition.text || condition}
                                </li>
                            ))
                        ) : (
                            <li>No conditions available</li> 
                        )}
                    </ul>
                </div>

                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.generalConditions")}</p>
                </div>
                <br />
                <br />
                <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <ul style={{ padding: 0 }}>
                        <li className="circle-list-item">{t("Details.sub32")}</li>
                        <li className="circle-list-item">{t("Details.sub33")}</li>
                        <li className="circle-list-item">{t("Details.sub34")}</li>
                        <li className="circle-list-item">{t("Details.sub35")}</li>
                        <li className="circle-list-item">{t("Details.sub36")}</li>
                        <li className="circle-list-item">{t("Details.sub37")}</li>
                    </ul>
                </div>

                <br />
                <div className="text-center">
                    <Link to="/user-info" state={{
                        cartItems: [{
                            type: optionName, quantity: 1, image1: image, Title: hospitalName,
                        }],
                        total: Number(price), 
                        id: id,
                        hospitalName: hospitalName,
                        hospitalLocation: hospitalLocation,
                        birthDescription: optionName,
                        specialConditions: conditionsSection ? conditionsSection.content : [],
                        bookingDetails: bookingDetails || [],
                        includedServices: includedServicesSection ? includedServicesSection.content : [],
                        serviceDetails: serviceDetails || [],
                    }}>
                        <button className='Services-button'>{t("Services.Pur16")}</button>
                        <br />
                        <br />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ServiceAdd_Details;
