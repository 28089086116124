import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pill from "../Pill";

const EstServices = () => {
    const { t, i18n } = useTranslation('global');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === 'ar' ? 'rtl' : 'ltr';
    };

    return (
        <div className='restricted-page mx-auto py-16 px-4' style={{ direction: getDirection() }}>
        <br />
        <br />
        <h1 className="title">{t('establishment_services.title')}</h1>
        <p className="message">{t('establishment_services.message')}</p>
        <p className="contact-info">
            <strong>{t('establishment_services.contact_info')}</strong>
        </p>
        <p className="email-info">
            <strong>{t('establishment_services.email_info')}</strong>
        </p>
        <p className="address">
            <strong>{t('establishment_services.address_jeddah')}</strong><br />
            <strong>{t('establishment_services.address_riyadh')}</strong>
        </p>
        <div className='mt-8'>
            <Link to='/contact_us' className='blueNewTkhile'>
                {t('establishment_services.contact_button')}
            </Link>
        </div>
        <Pill />
    </div>
    );
};

export default EstServices;
