import React, { useRef, useMemo, useEffect } from 'react';
import Slider from 'react-slick';
import "./Sketch.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';


import ban1 from "../components/images/ban1.png";
import ban2 from "../components/images/ban2.png";
import ban3 from "../components/images/ban3.png";
import ban4 from "../components/images/ban4.png";
import card1Img from '../components/images/cart1.png';
import card2Img from '../components/images/cart2.png';
import card3Img from '../components/images/cart3.png';
import icon1Img from '../components/images/icon1.png';
import icon2Img from '../components/images/icon2.png';
import icon3Img from '../components/images/icon3.png';
import icon4Img from '../components/images/icon4.png';
import one from '../components/images/10.png';
import { useAuthContext } from '../context/AuthContext';




const Sketch = () => {
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };
  const sliderRef = useRef(null);
  const iconSliderRef = useRef(null);
  const iconSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
    accessibility: true,
    arrows: false
  };



  const iconSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
    accessibility: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,  // This means the screen width is up to 768px
        settings: {
          slidesToShow: 2,  // Shows 2 icons for screens up to 768px wide
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,  // Shows 1 icon for screens up to 480px wide
          slidesToScroll: 1
        }
      }
    ]
  };
  const cardsData = [
    {
      img: card3Img,
      title: t("Sketch.title3"),
      text: t("Sketch.text3"),
      link: user ? "/" : "/signup"
    },
    {
      img: card2Img,
      title: t("Sketch.title2"), // Use the translation for the title
      text: t("Sketch.text2"),
      link: user ? "/" : "/signup"
    },


    {
      img: card1Img,
      title: t("Sketch.title1"), // Use the translation for the title
      text: t("Sketch.text1"),
      link: user ? "/" : "/signup"
    },



  ];

  const banners = useMemo(() => [
    { img: ban1, text: " " },
    { img: ban2, text: "" },
    { img: ban3, text: "" },
    { img: ban4, text: "" }
  ], []);
  const iconData = [

    {
      img: icon1Img,
      title: t("AdditionalS.title1"),
      text: t("AdditionalS.text1"),
      link: user ? "/" : "/signup"
    },
    {
      img: icon2Img,
      title: t("AdditionalS.title2"),
      text: t("AdditionalS.text2"),
      link: user ? "/" : "/signup"  // Conditional link based on user
    },
    {
      img: icon3Img,
      title: t("AdditionalS.title3"),
      text: t("AdditionalS.text3"),
      link: user ? "/" : "/signup"  // Conditional link based on user
    },
    {
      img: icon4Img,
      title: t("AdditionalS.title4"),
      text: t("AdditionalS.text4"),
      link: user ? "/" : "/signup"  // Conditional link based on user
    },
  ];
  const Icon = ({ img, title, text, link }) => (
    <a href={link} className="cart">
      <img src={img} alt={title} className="cart-img-top" />
      <div className="cartsbody">
        <h5 className="cartstitle">{title}</h5>
        <p className="cartstext">{text}</p>
      </div>
    </a>
  );
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    accessibility: true
    ,
    arrows: false

  };
  const Cart = ({ img, title, text, link }) => (
    <a href={link} className="cart">
      <img src={img} alt={title}  />
      <div className="cart-body">
        <br/>
        <p className="TitleConta">{title}</p>

        <p className="textBCont">{text}</p>
      </div>

    </a>
  );
  return (
    <div className='backgroundwhite' >
      <div className="banner-container">
        <Slider {...settings} ref={sliderRef}>
          {banners.map((banner, index) => (
            <div key={index} className="banner-slide">
              <img src={banner.img} alt={`Banner ${index + 1}`} className="banner-image" />
              <div className="banner-text">{banner.text}</div>
            </div>
          ))}
        </Slider>


        <div className="btn-container">
          <button className="btn-content prev-button" onClick={() => sliderRef.current.slickPrev()} aria-label="Previous slide">
            <span className="icon-arrow" id="arrow-icon-one">&#10094;</span>
          </button>
          <button className="btn-content next-button" onClick={() => sliderRef.current.slickNext()} aria-label="Next slide">
            <span className="icon-arrow" id="arrow-icon-two">&#10095;</span>
          </button>
        </div>
      </div>
      <div className="hospital-text" >
        <p className="captionF"     style={{ direction: getDirection() }}>
          {t("Sketch.Caption")}
        </p>
      </div>
      <div className="blad11">
        <div className="blad12">
          {cardsData.map((cart, index) => ( 
            <Cart key={index} {...cart} />
          ))}
        </div>
      </div>
      <br />
      <div className='Additional' >
        <br/>
        <h2 className='titleAdditional text-center'>
          {t("AdditionalS.Title")}
        </h2>

        <p className='txtAdditional text-center'>
          {t("AdditionalS.txtcaption")}
        </p>
        <br />
        <Slider {...iconSettings} ref={iconSliderRef}>
          {iconData.map((icon, index) => (
            <div key={index} className="icSlide">
              <Icon {...icon} />
            </div>
          ))} 
        </Slider>
      </div>
      <br />
      <div className='addplt' style={{ direction: getDirection() }}>
        <div className="addplt-content"><br /><br />
          <h2 className='titleaddplt'>{t("AdditionalS.Plt1")}</h2>
          <div className="content-row">
            <div className="image-column">
              <img src={one} alt="Image" className="addplt-image" />
            </div>
            <div className="text-column">
              <p className='txtaddplt'><br /><br />
                {t("AdditionalS.Plt2")}
              </p>
              <p className='txtaddplt'>
                {t("AdditionalS.Plt3")}
              </p>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
};

export default Sketch;



















