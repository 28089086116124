import React from "react";
import "./Pill.css"; // Make sure to import the CSS file

const Pill = () => {
  return (
    <div className="pill12">
      <div className="pill">
        <div className="medicine">
          {Array.from({ length: 19 }).map((_, index) => (
            <i key={index}></i>
          ))}
        </div>
        <div className="side"></div>
        <div className="side"></div>
      </div>
    </div>
  );
};

export default Pill;
