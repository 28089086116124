import React, { useState, useEffect } from 'react';
import { useAuthContext } from "../context/AuthContext";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Typography, CircularProgress, Card, CardContent } from "@mui/material";
import { useLocation, Link } from 'react-router-dom';

import "./MYcontracts.css";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const MYcontracts = () => {
    const { user } = useAuthContext();
    const [pdfs, setPdfs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPdfsAndOrders = async () => {
            if (user && user.emailVerified) {
                try {
                    const [pdfData, ordersData] = await Promise.all([
                        fetchCollectionData("pdfs", user.email),
                        fetchCollectionData("orders", user.email)
                    ]);

                    const mergedData = mergeData(pdfData, ordersData);
                    setPdfs(mergedData);
                } catch (error) {
                    console.error("Error fetching PDFs and Orders:", error);
                    setError("Failed to load contracts. Please try again later.");
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchPdfsAndOrders();
    }, [user]);

    const fetchCollectionData = async (collectionName, email) => {
        const ref = collection(firestore, collectionName);
        const q = query(ref, where("email", "==", email));
        const querySnapshot = await getDocs(q);
        const data = [];
        querySnapshot.forEach(doc => {
            data.push({ id: doc.id, ...doc.data() });
        });
        console.log(`${collectionName} data:`, data); // Debugging line
        return data;
    };

    const mergeData = (pdfData, ordersData) => {
        return pdfData.map(pdf => {
            const order = ordersData.find(order => order.id === pdf.id);
            return { ...pdf, ...order };
        });
    };

    const formatDate = (dateStr) => {
        if (!dateStr) return "No Date Available";
        const dateParts = dateStr.split("@");
        return dateParts.length > 0 ? dateParts[0].trim() : "No Date Available";
    };

    return (
        <div className="my-contracts-container">
            <Typography variant="h4" component="div" align="center" gutterBottom>
                MY Contracts
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Typography variant="body1" color="error" align="center">
                    {error}
                </Typography>
            ) : (
                <div className="pdf-list">
                    {pdfs.length > 0 ? (
                        pdfs.map(pdf => (
                            <Card key={pdf.id} className="pdf-item">
                                <CardContent>
                                    <Typography variant="h6" component="p">
                                        {pdf.name || "No Name Available"}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {formatDate(pdf.Date)}
                                    </Typography>
                                    <Typography variant="body1" component="p">
                                        <a href={pdf.pdfUrl} target="_blank" rel="noopener noreferrer">
                                            View PDF
                                        </a>
                                    </Typography>
                                    <button className="button-download">
                                        <Link to="/Document_Your_Contract" state={{

                                        }}>
                                            <p style={{ color: 'white' }}>
                                                وثق العقد
                                            </p>
                                        </Link>
                                    </button>
                                </CardContent>
                            </Card>
                        ))
                    ) : (
                        <Typography variant="body1" align="center">
                            No contracts found.
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );
};

export default MYcontracts;
