import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon2Img from '../components/images/se.png';
import './Service_Details.css';

const Service_Details = () => {
    const location = useLocation();
    const { id, price, optionName, hospitalName, hospitalLocation,hospitalbranche, image, bookingDetails,idReal } = location.state || {};
    const [title, setTitle] = useState(''); 

    const [includedServices, setIncludedServices] = useState(null);
    const [specialConditions, setSpecialConditions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t, i18n } = useTranslation("global");
    useEffect(() => {
        if (optionName) {
            console.log('Hospital optionName:', optionName);
        } else {
            console.error('Error: Hospital branch information is missing.');
        }
    }, [optionName]);
    
    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchServiceDetails = async () => {
            try {
                // const response = await fetch(`https://lahalak.sa/backend/getAdditionalServices/gettwovalue.php?service_id=${id}`);
                const response = await fetch(`${process.env.REACT_APP_API_URL3}?service_id=${id}`);

                const data = await response.json();
                setTitle(data.title); // Set the title from the response

                setIncludedServices(data.included_services);
                setSpecialConditions(data.special_conditions);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch service details');
                setLoading(false);
            }
        };
        

        if (id) {
            fetchServiceDetails();
        }
    }, [id]);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const renderContent = (content) => {
        if (Array.isArray(content)) {
            return content.map((item, index) => (
                <div className='titlecollapseeB' key={index}>
                    <p>{item.text || item}</p>
                </div>
            ));
        } else {
            return (
                <div className='titlecollapseeB'>
                    <img src={icon2Img} alt="Icon" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <p>{content}</p>
                </div>
            );
        }
    };

    return (
        <div className="backgroundwhiteASS" dir={getDirection(i18n.language)}>
            <br />
            <br />
            
            <br />
            <p className='TitlContwo' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {t("Details.sub")}
            </p>
            <br />
            <div>
                <br />
                <p className='titlecollapsee'>{t("Details.sub1")}</p>
                <div className='articleContent'>
                    <div className='det_gr'>
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub2")}</p>
                            <p>{hospitalName}</p>
                        </div>
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub3")}</p>
                            <p>{hospitalLocation}</p>
                        </div>
                         
                            <div className='gellule'>
                                <p className='titlenormalcolapsText'>{t("Details.sub4")}</p>
                                <p>{optionName}</p>
                            </div>
                      
                        <div className='gellule'>
                            <p className='titlenormalcolapsText'>{t("Details.sub5")}</p>
                            <p>{price}</p>
                        </div>
                    </div>
                </div>

                {includedServices ? (
                    <>
                        <div className='Bluediv'>
                            <p className='titlecollapseeWak'>{t("Details.includedServices")}</p>
                        </div>
                        <br />
                        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                <li className="iconTrue" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <div style={{ flex: 1 }}>
                                        {renderContent(includedServices)}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </>
                ) : (
                    <p>{t("Details.noIncludedServices")}</p>
                )}

                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.addedValues")}</p>
                </div>
                <br />
                <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    {bookingDetails && bookingDetails.map((detail, index) => (
                        <div key={index}>
                            {Object.entries(detail).map(([key, value]) => (
                                <ul key={key}>
                                    <li className="iconTrue">
                                        <img src={icon2Img} alt="Icon" style={{ width: '15px', height: '15px' }} />
                                        <p>{value}</p>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    ))}
                </div>

                {/* Special Conditions Section */}
                {specialConditions ? (
                    <>
                        <div className='Bluediv'>
                            <p className='titlecollapseeWak'>{t("Details.specialConditions")}</p>
                        </div>
                        <br />
                        <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            <ul style={{ padding: 0 }}>
                                {renderContent(specialConditions)}
                            </ul>
                        </div>
                    </>
                ) : (
                    <p>{t("Details.noSpecialConditions")}</p>
                )}

                {/* General Conditions Section */}
                <div className='Bluediv'>
                    <p className='titlecollapseeWak'>{t("Details.generalConditions")}</p>
                </div>
                <br />
                <br />
                <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <ul style={{ padding: 0 }}>
                        <li className="circle-list-item">{t("Details.sub32")}</li>
                        <li className="circle-list-item">{t("Details.sub33")}</li>
                        <li className="circle-list-item">{t("Details.sub34")}</li>
                        <li className="circle-list-item">{t("Details.sub37")}</li>
                    </ul>
                </div>

                <br />
                <div className="text-center">
                    <Link to="/user-info" state={{
                        cartItems: [{
                            type: optionName, quantity: 1, image1: image, Title: hospitalName,
                        }], 
                        total: Number(price),
                        id: id,
                        hospitalName: hospitalName,
                        hospitalLocation: hospitalLocation,
                        birthDescription: optionName,
                        hospitalbranche: hospitalbranche,
                        specialConditions: specialConditions || [],
                        bookingDetails: bookingDetails || [],
                        includedServices: includedServices || [],
                        sevicename:title||[],
                        idReal:idReal||[],
                    }}>
                        <button className='Services-button'>{t("Services.Pur16")}</button>
                        <br />
                        <br />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Service_Details;
