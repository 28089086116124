import React, { useEffect, useState } from "react";
import "./Automation.css";
import { useTranslation } from 'react-i18next';
import back from '../components/images/our_partner.png';
import './OurPartner.css'; // Optional CSS file for styling 

const OurPartner = () => {
    const { t, i18n } = useTranslation("global");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const partners = [
        {
            name: t("Footersection.name5"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Care4Y.png?alt=media&token=d67cb04f-6971-4975-b2c1-ecf15edc4dc9",
            location: t("Footersection.location5")
        },
        {
            name: t("Footersection.name7"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            location: t("Footersection.location7")
        },
        {
            name: t("Footersection.name9"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/andalusia.png?alt=media&token=3ad14da9-a5ef-41b2-81d7-aef6ca177081",
            location: t("Footersection.location9")
        },
        {
            name: t("Footersection.name13"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/MY_Clinic.png?alt=media&token=11d46c4c-ac51-4edc-ab56-bb177e1edc3e",
            location: t("Footersection.location44")
        },
        {
            name: t("Footersection.name10"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/passport_fc84a337-3840-4dd4-bacf-671c391447c6.png?alt=media&token=4ebfc2aa-3852-446b-9fee-743bc5c2b798",
            location: t("Footersection.location10")
        },
        {
            name: t("Footersection.name8"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            location: t("Footersection.location8")
        },
        {
            name: t("Footersection.name11"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Alia_Al_Farid.png?alt=media&token=52157d0e-bf5e-48d8-a91e-9516e9e3605e",
            location: t("Footersection.location11")
        },
        {
            name: t("Footersection.name6"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/sakinia.png?alt=media&token=22935df8-dd34-4084-8df3-39a649b67671",
            location: t("Footersection.location6") // Jeddah
        },
        {
            name: t("Footersection.name12"), // Saudi German Hospital - Mecca
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            location: t("Footersection.location12") // Mecca
        },
        {
            name: t("Footersection.name13"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/MY_Clinic.png?alt=media&token=11d46c4c-ac51-4edc-ab56-bb177e1edc3e",
            location: t("Footersection.location13")
        },
        {
            name: t("Footersection.name14"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Aya.png?alt=media&token=980cd7f3-c37e-42ea-adfb-06b01fd30cc8",
            location: t("Footersection.location14")
        },
        {
            name: t("Footersection.name15"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/wid.png?alt=media&token=255e6a62-9956-40a9-9289-5169a2439c7e",
            location: t("Footersection.location15")
        },
        {
            name: t("Footersection.name16"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            location: t("Footersection.location16")
        },
        {
            name: t("Footersection.name17"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/midlab.png?alt=media&token=df20ea10-b449-4e16-ba93-307824043bf9",
            location: t("Footersection.location17")
        },
        {
            name: t("Footersection.name18"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Award_al_beshri_hospital.jpg?alt=media&token=42ac8117-9515-44bb-ae9f-54afb4065143",
            location: t("Footersection.location18")
        },

        {
            name: t("Footersection.name19"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/ACL.png?alt=media&token=00d3067d-dd4e-4ba9-a936-622228101960",
            location: t("Footersection.location19")
        },
        {
            name: t("Footersection.name20"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/BADGHISH-logo-ar-1.png?alt=media&token=0da7928d-0429-4c3f-833d-ab20a4852038",
            location: t("Footersection.location20")
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div className={getDirection() + " backgroundwhiteASS"} style={{ direction: getDirection() }}>
            <div className="bannersol" style={{ backgroundImage: `url(${back})` }}>
            </div>
            <div className="bahite" dir={getDirection(i18n.language)}>
                <br />
                <div>
                    <p className="GoldCont">{t("footer.partnersTitle")}</p>
                </div>
                <br />
                <div className="partners-container">
                    {partners.map((partner, index) => (
                        <div key={index} className="partner-item">
                            <img src={partner.imgSrc} alt={partner.name} style={{ width: '100%', height: 'auto' }} />
                            <p>{partner.name}</p>
                            <p>{partner.location}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurPartner;
