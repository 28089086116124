
import { Link } from 'react-router-dom';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Madalogo from './images/payment_option/Mada_footerlogo.png';
import Footersection from './Footersection.js';
import mclogo from './images/payment_option/mc_footerlogo.png';
import { FaClock, FaThumbsUp, FaQuestionCircle, FaCheckCircle, FaUsers, FaTachometerAlt, FaMedal, FaEnvelopeOpenText, FaStethoscope, FaUserFriends } from 'react-icons/fa';

import visalogo from './images/payment_option/visa_footerlogo.png';
import './Footer.css';

const Footer = () => {
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  return (
    <div>
      <div className='footer-container mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300' style={{ direction: getDirection() }}>
        <div className='lg:col-span-2 flex flex-col lg:flex-row justify-between'>
          <div className='mb-8 lg:mb-0'>
            <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.solutions")}</h6>
            <ul className='mt-2 lg:mr-8'>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/Est_Services"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("footer.Est_Services")}
                </Link>
              </li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/Service_providers"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("footer.legal_assistance")}
                </Link>
              </li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/Job_applications"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("footer.Job_applications")}
                </Link>
              </li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/Establishment_Services_assistance"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("footer.Establishment_Services_assistance")}
                </Link>
              </li>
            </ul>
          </div>
          <div className='mb-8 lg:mb-0'>
            {/* عن المنصة" */}
            <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.service")}</h6>
            <ul className='mt-2 lg:mr-8'>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link className=''
                  to="/support"
                > <p className='footerFontWhite'>{t("footer.support")}</p>
                </Link>
              </li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/automation"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("footer.news")}
                </Link>
              </li>

              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/Latestnews"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("Navbar.News")}
                </Link>
              </li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/automation"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("Navbar.Solutions")}
                </Link>
              </li>

              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/Buying_guide"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("Navbar.dalilshira")}
                </Link>
              </li>

           

              
            </ul>
          </div>
          <div className='mb-8 lg:mb-0'>
            <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.help")}</h6>
            <ul className='mt-2 lg:mr-8'>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link to={"/contact_us"} style={{ fontSize: '1rem', color: 'white' }}>
                  {t("footer.help")}
                </Link>
              </li>

              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link style={{ color: '#FFFFFF' }} to="/SendMessage" state={{ MessagTitle: "شكوى" }}>
                  {t("footer.File_a_complaint")}
                </Link>
              </li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <Link style={{ color: '#FFFFFF' }} to="/SendMessage" state={{ MessagTitle: "اعتراض" }}>
                  {t("footer.File_an_objection")}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h6 style={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'white' }}>{t("footer.contact_us")}</h6>
            <ul className='mt-2'>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>
                <a className="py-2 text-white" href={" https://www.google.com/maps/place/%D9%85%D9%86%D8%B5%D8%A9+%D9%84%D9%87%D8%A7+%D9%84%D9%83+%D8%A7%D9%84%D8%B7%D8%A8%D9%8A%D8%A9%E2%80%AD/@21.5256193,39.1699823,18.04z/data=!4m6!3m5!1s0x15c3d15649200e27:0x6d056ff84ef35803!8m2!3d21.5257418!4d39.1696576!16s%2Fg%2F11ptlqg17k?entry=ttu"}
                >
                  {t("footer.jeddah_address")}
                </a>

              </li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>{t("footer.riyadh_address")}</li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>920014308</li>

              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>+966 57 160 5516</li>
              <li className='py-2 text-sm' style={{ fontSize: '1rem', color: 'white' }}>{t("footer.email")}</li>
            </ul>
          </div>
        </div>

        <div className="lg:col-span-2 flex flex-col lg:flex-row justify-between">
          <Link to={"/Contract_provisions"} >
            <p style={{ color: '#FFFFFF' }}>{t("footer.faq")}</p>
          </Link>
          <Link to={"/privacy_policy"} >
            <p style={{ color: '#FFFFFF' }}>{t("footer.privacy_policy")}</p> 
          </Link>
          <Link to={"/Terms_and_conditions"} >
            <p style={{ color: '#FFFFFF' }}>{t("footer.terms_and_conditions")}</p>
          </Link>
          <Link to={"/Privacy"} >
            <p style={{ color: '#FFFFFF' }}>{t("footer.privce")}</p>
          </Link>
        </div>


        <div className="flex justify-start mt-4">
          <img src={Madalogo} alt="Mada" className="mx-2 h-6" />
          <img src={mclogo} alt="MasterCard" className="mx-2 h-6" />
          {/* <img src={tabbylogo} alt="Tabby" className="mx-2 h-6" /> */}
          <img src={visalogo} alt="Visa" className="mx-2 h-6" />
        </div>
        <div className='centre'>
          <p className='text-sm text-gray-400' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            &copy; جميع حقوق البيع والنشر والتوزيع محفوظة.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;



