import { Link } from 'react-router-dom';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div style={{ direction: getDirection(), backgroundColor: "white", padding: "20px", borderRadius: "8px" }}>
            <h1>{t("privacy.title")}</h1>
            <p>{t("privacy.description")}</p>
            <ol>
                <li>
                    <h2>{t("privacy.privacy1")}</h2>
                    <p>{t("privacy.privacy2")}</p>
                    <p>{t("privacy.privacy3")}</p>
                    <p>{t("privacy.privacy4")}</p>
                </li>
                <li>
                    <h2>{t("privacy.privacy5")}</h2>
                    <p>{t("privacy.privacy6")}</p>
                    <p>{t("privacy.privacy7")}</p>
                </li>
                <li>
                    <h2>{t("privacy.privacy8")}</h2>
                    <p>{t("privacy.privacy9")}</p>
                    <p>{t("privacy.privacy10")}</p>
                </li>
                <li>
                    <h2>{t("privacy.privacy11")}</h2>
                    <p>{t("privacy.privacy12")}</p>
                    <p>{t("privacy.privacy13")}</p>
                </li>
                <li>
                    <h2>{t("privacy.privacy14")}</h2>
                    <p>{t("privacy.privacy15")}</p>
                    <p>{t("privacy.privacy16")}</p>
                </li>
                <li>
                    <h2>{t("privacy.privacy17")}</h2>
                    <p>{t("privacy.privacy18")}</p>
                    <p>{t("privacy.privacy19")}</p>
                </li>
                <li>
                    <h2>{t("privacy.privacy20")}</h2>
                    <p>{t("privacy.privacy21")}</p>
                    <p>{t("privacy.privacy22")}</p>
                </li>
                <li>
                    <h2>{t("privacy.privacy23")}</h2>
                    <p>{t("privacy.privacy24")}</p>
                    <p>{t("privacy.privacy25")}</p>
                </li>
            </ol>
        </div>
    );
};

export default Privacy;
