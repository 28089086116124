import React from "react";
import Sketch from "./Sketch";
import Slideshow from "./Slideshow";
import Solution from "./Solution";
import About from "./About";
import './Homee.css';

const Home_page = () => {
  return (
    <div className="backgroundwhite">
      <div>
        <Sketch />
      </div>
      <div >
        <Solution />
      </div> 
      <br/>
      <div >
        <Slideshow />
      </div>
     

      <div >
        <About />
      </div>
    </div>
  );
};

export default Home_page;
