import React, { useEffect } from "react";
import "./Connectivity.css";
import Headsol from "./Headsol";
import { useTranslation } from 'react-i18next';

const Connectivity = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    return (
        <div >
            <div>
                <Headsol />
            </div>
            <div>
                <h1 className="titleh" style={{ direction: getDirection() }}>
                    {t("Connectivity.ConnectivityTitel")}
                </h1>
                <br />  <br />
                <br />  <br />
                <h3 className="titlem" style={{ direction: getDirection() }}>
                    {/* {t("Connectivity.TelephoneSystems")} */}
                </h3>
                <br />
                <br />
                <div  >
                    <div className="col-md-6" style={{ direction: getDirection() }}>
                        <div className="automationtxt colmd6">
                                                        <p style={{ color: 'black', direction: getDirection() }}>

                                {t("Connectivity.TelephoneSystems1")}

                                <br />
                                <br />
                                <br />

                                {t("Connectivity.TelephoneSystems2")}
                                <br />
                                <br />
                                <br />                
                                                {t("Connectivity.TelephoneSystems3")}

                                <br />

                                {t("Connectivity.TelephoneSystems4")}
                                <br />

                                {t("Connectivity.TelephoneSystems5")}
                                <br />
                                {t("Connectivity.TelephoneSystems6")}
                                <br />
                                {t("Connectivity.TelephoneSystems7")}
                                <br />
                                {t("Connectivity.TelephoneSystems8")}
                                <br />
                                <br />
                                {t("Connectivity.TelephoneSystems9")}
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/avaya.png"
                            alt="Phone"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
            <div>
                <br />
                <br />
                <br />
                <h3 className="titlem" style={{ direction: getDirection() }}>
                    {/* {t("dataNetworkSystems.DataNetworkSystems")} */}
                </h3>
                <br />
                <br />
                <div  >
                    <div className="col-md-6" style={{ direction: getDirection() }}>
                        <div className="automationtxt colmd6">
                                                        <p style={{ color: 'black', direction: getDirection() }}>

                                {t("dataNetworkSystems.DataNetworkSystems1")}
                                <br /><br />
                                {t("dataNetworkSystems.DataNetworkSystems2")}
                                <br />   <br />
                                <br />
                                {t("dataNetworkSystems.WiredNetworkSolutions")}
                                <br />
                                {t("dataNetworkSystems.WiredNetworkSolutionsDesc")}

                                <br />
                                <br />
                                {t("dataNetworkSystems.NetworkSecurityAndThreatMitigation")}
                                {t("dataNetworkSystems.NetworkSecurityAndThreatMitigationDesc")}
                                <br />
                                <br />
                                {t("dataNetworkSystems.UnifiedNetworkManagement")}
                                {t("dataNetworkSystems.UnifiedNetworkManagementDesc")}
                                <br />
                                <br />
                                {t("dataNetworkSystems.comprehensiveSuiteOfOfferings")}

                                <br />
                            </p>

                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/switch.jpg"
                            alt="switch"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
            <div>
                <br />
                <br />  <br />
                <h3 className="titlem" style={{ direction: getDirection() }}>
                    {/* {t("videoDistribution.Video_Distribution")} */}
                </h3>
                <br />
                <div  >
                    <div className="col-md-6" style={{ direction: getDirection() }}>
                        <div className="automationtxt colmd6">
                                                        <p style={{ color: 'black', direction: getDirection() }}>
{t("videoDistribution.intro")}
                                <br />
                                <br />
                                <br />
                                {t("videoDistribution.innovation")}

                                <br />
                                <br />
                                <br />
                                {t("videoDistribution.portfolio")}

                                <br />
                                <br />
                                <br />
                                {t("videoDistribution.solution1")}
                                <br />
                                {t("videoDistribution.solution2")}
                                <br />
                                {t("videoDistribution.solution3")}
                                <br />  
                                {t("videoDistribution.solution4")}
                                <br />  
                                {t("videoDistribution.solution5")}
                                <br />  
                                {t("videoDistribution.solution6")}
                                <br /> 
                                <br />
                                {t("videoDistribution.comprehensiveSuite")}
                            </p>
                        </div>
                    </div>

                    <div className="colmd6">
                        <img
                            src="assets/Video_distrubition.jpg"
                            alt="switch"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    );
};

export default Connectivity;
