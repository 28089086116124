import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import './My_appointments.css';

const MyAppointments = () => {
    const { user } = useAuthContext(); // Extract user info from context
    const [appointments, setAppointments] = useState([]); // State for appointments data
    const [pdfUrl, setPdfUrl] = useState(null); // State for the PDF URL
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // State for potential error
    const { t, i18n } = useTranslation('global'); // Translation hook
    const [convertedPhone, setConvertedPhone] = useState(''); 

    // Function to convert phone number to the 966 format
    const convertPhoneNumber = (phone) => {
        if (phone && phone.startsWith('0')) {
            return `966${phone.slice(1)}`; // Replace the first '0' with '966'
        }
        return phone; // If the number doesn't start with '0', return it as is
    };

    useEffect(() => {
        const fetchAppointments = async () => {
            if (user?.phone) { // Check if phone number exists
                const converted = convertPhoneNumber(user.phone); // Convert phone number
                setConvertedPhone(converted); // Set the converted phone

                try {
                    const fetchAppointmentsUrl = process.env.REACT_APP_FETCH_APPOINTMENTS_URL;
                
                    console.log('Fetching appointments for phone:', converted); // Log the converted phone number
                    const response = await fetch(fetchAppointmentsUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ phone: converted }), 
                    });
                
                    // Check if response is OK and parse it
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    
                    const data = await response.json(); // Parse JSON response
                    console.log('Appointments response:', data); 
                
                    if (data.status === 'success') {
                        setAppointments(data.appointments); // Update appointments state
                        setPdfUrl(data.pdfUrl || null); // Set the PDF URL if available
                    } else {
                        setAppointments([]); // Set an empty array if no appointments are found
                        setPdfUrl(null); // Clear PDF URL if not found
                        console.log('No appointments or PDF found');
                    }
                } catch (error) {
                    console.error('Error fetching appointments:', error); // Log the error
                    setError(error.message); // Set error message
                } finally {
                    setLoading(false); // End loading state
                }
                
            }
        };

        fetchAppointments(); // Call the function
    }, [user?.phone]);

    const getDirection = () => {
        return i18n.language === 'ar' ? 'rtl' : 'ltr';
    };

    if (loading) {
        return <div>{t('Appointments.loading')}</div>; 
    }

    if (error) {
        return <div>{t('Appointments.error')}: {error}</div>; 
    }

    return (
        <div className="appointments-page" style={{ direction: getDirection() }}>
            <h1>{t('Appointments.title')}</h1>
            {appointments.length > 0 ? (
                <ul className="appointments-list">
                    {appointments.map((appointment, index) => (
                        <li key={index} className="appointment-item">
                            <div>{t('Appointments.date')}: {appointment.selectedDate}</div>
                            <div>{t('Appointments.time')}: {appointment.selectedTime}</div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>{t('Appointments.noAppointments')}</p>
            )}
            
            {pdfUrl && (
                <div className="pdf-download-section">
                    <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                        <button className="pdf-download-button">
                            {t('Appointments.downloadPDF')}
                        </button>
                    </a>
                </div>
            )}
        </div>
    );
};

export default MyAppointments;
