import { Link } from 'react-router-dom';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Contract_provisions = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div style={{ direction: getDirection(), backgroundColor: "white", padding: "20px", borderRadius: "8px" }}>
            <h1>{t("contract_provisions.title")}</h1>
            <p>{t("contract_provisions.description")}</p>
            <ol>
                <li>
                    <h2>{t("contract_provisions.provision1")}</h2>
                    <p>{t("contract_provisions.provision2")}</p>
                </li>
                <li>
                    <h2>{t("contract_provisions.provision3")}</h2>
                    <ul>
                        <li>{t("contract_provisions.provision4")}</li>
                        <li>{t("contract_provisions.provision5")}</li>
                    </ul>
                </li>
                <li>
                    <h2>{t("contract_provisions.provision6")}</h2>
                    <ul>
                        <li>{t("contract_provisions.provision7")}</li>
                        <li>{t("contract_provisions.provision8")}</li>
                    </ul>
                </li>
                <li>
                    <h2>{t("contract_provisions.provision9")}</h2>
                    <ul>
                        <li>{t("contract_provisions.provision10")}</li>
                        <li>{t("contract_provisions.provision11")}</li>
                        <li>{t("contract_provisions.provision12")}</li>
                    </ul>
                </li>
                <li>
                    <h2>{t("contract_provisions.provision13")}</h2>
                    <ul>
                        <li>{t("contract_provisions.provision14")}</li>
                        <li>{t("contract_provisions.provision15")}</li>
                    </ul>
                </li>
                <li>
                    <h2>{t("contract_provisions.provision16")}</h2>
                    <ul>
                        <li>{t("contract_provisions.provision17")}</li>
                        <li>{t("contract_provisions.provision18")}</li>
                    </ul>
                </li>
            </ol>
        </div>
    );
};

export default Contract_provisions;
