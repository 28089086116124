import { Link } from 'react-router-dom';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Terms_and_conditions = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div style={{ direction: getDirection(), backgroundColor: "white", padding: "20px", borderRadius: "8px" }}>
            <ol>
                <ul>
                    <h2>{t("user_agreement.section1")}</h2>
                    <li>
                        <li>{t("user_agreement.section2")}</li>
                        <li>{t("user_agreement.section3")}</li>
                    </li>
                </ul>

                <ul>
                    <h2>{t("user_agreement.section4")}</h2>
                    <ul>
                        <li>{t("user_agreement.section5")}</li>
                        <li>{t("user_agreement.section6")}</li>
                        <li>{t("user_agreement.section7")}</li>
                        <li>{t("user_agreement.section8")}</li>
                        <li>{t("user_agreement.section9")}</li>
                        <li>{t("user_agreement.section10")}</li>
                        <li>{t("user_agreement.section11")}</li>
                        <li>{t("user_agreement.section12")}</li>
                        <li>{t("user_agreement.section13")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section14")}</h2>
                    <ul>
                        <li>{t("user_agreement.section15")}</li>
                        <li>{t("user_agreement.section16")}</li>
                        <li>{t("user_agreement.section17")}</li>
                        <li>{t("user_agreement.section18")}</li>
                        <li>{t("user_agreement.section19")}</li>
                        <li>{t("user_agreement.section20")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section21")}</h2>
                    <ul>
                        <li>{t("user_agreement.section22")}</li>
                        <li>{t("user_agreement.section23")}</li>
                        <li>{t("user_agreement.section24")}</li>
                        <li>{t("user_agreement.section25")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section26")}</h2>
                    <ul>
                        <li>{t("user_agreement.section27")}</li>
                        <li>{t("user_agreement.section28")}</li>
                        <li>{t("user_agreement.section29")}</li>
                        <li>{t("user_agreement.section30")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section31")}</h2>
                    <ul>
                        <li>{t("user_agreement.section32")}</li>
                        <li>{t("user_agreement.section33")}</li>
                        <li>{t("user_agreement.section34")}</li>
                        <li>{t("user_agreement.section35")}</li>
                        <li>{t("user_agreement.section36")}</li>
                        <li>{t("user_agreement.section37")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section38")}</h2>
                    <ul>
                        <li>{t("user_agreement.section39")}</li>
                        <li>{t("user_agreement.section40")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section41")}</h2>
                    <ul>
                        <li>{t("user_agreement.section42")}</li>
                        <li>{t("user_agreement.section43")}</li>
                        <li>{t("user_agreement.section44")}</li>
                        <li>{t("user_agreement.section45")}</li>
                        <li>{t("user_agreement.section46")}</li>
                        <li>{t("user_agreement.section47")}</li>
                        <li>{t("user_agreement.section48")}</li>
                        <li>{t("user_agreement.section49")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section50")}</h2>
                    <ul>
                        <li>{t("user_agreement.section51")}</li>
                        <li>{t("user_agreement.section52")}</li>
                        <li>{t("user_agreement.section53")}</li>
                        <li>{t("user_agreement.section54")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section55")}</h2>
                    <ul>
                        <li>{t("user_agreement.section56")}</li>
                        <li>{t("user_agreement.section57")}</li>
                        <li>{t("user_agreement.section58")}</li>
                        <li>{t("user_agreement.section59")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section60")}</h2>
                    <ul>
                        <li>{t("user_agreement.section61")}</li>
                        <li>{t("user_agreement.section62")}</li>
                        <li>{t("user_agreement.section63")}</li>
                        <li>{t("user_agreement.section64")}</li>
                        <li>{t("user_agreement.section65")}</li>
                        <li>{t("user_agreement.section66")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section67")}</h2>
                    <ul>
                        <li>{t("user_agreement.section68")}</li>
                        <li>{t("user_agreement.section69")}</li>
                        <li>{t("user_agreement.section70")}</li>
                        <li>{t("user_agreement.section71")}</li>
                        <li>{t("user_agreement.section72")}</li>
                        <li>{t("user_agreement.section73")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section74")}</h2>
                    <ul>
                        <li>{t("user_agreement.section75")}</li>
                        <li>{t("user_agreement.section76")}</li>
                        <li>{t("user_agreement.section77")}</li>
                        <li>{t("user_agreement.section78")}</li>
                        <li>{t("user_agreement.section79")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section80")}</h2>
                    <ul>
                        <li>{t("user_agreement.section81")}</li>
                        <li>{t("user_agreement.section82")}</li>
                        <li>{t("user_agreement.section83")}</li>
                        <li>{t("user_agreement.section84")}</li>
                        <li>{t("user_agreement.section85")}</li>
                        <li>{t("user_agreement.section86")}</li>
                        <li>{t("user_agreement.section87")}</li>
                        <li>{t("user_agreement.section88")}</li>
                        <li>{t("user_agreement.section89")}</li>
                        <li>{t("user_agreement.section90")}</li>
                    </ul>
                </ul>

                <ul>
                    <h2>{t("user_agreement.section91")}</h2>
                    <ul>
                        <li>{t("user_agreement.section92")}</li>
                        <li>{t("user_agreement.section93")}</li>
                        <li>{t("user_agreement.section94")}</li>
                        <li>{t("user_agreement.section95")}</li>
                        <li>{t("user_agreement.section96")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section97")}</h2>
                    <ul>
                        <li>{t("user_agreement.section98")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section99")}</h2>
                    <ul>
                        <li>{t("user_agreement.section100")}</li>
                        <li>{t("user_agreement.section101")}</li>
                        <li>{t("user_agreement.section102")}</li>
                        <li>{t("user_agreement.section103")}</li>
                    </ul>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section104")}</h2>
                </ul>
                <ul>
                    <h2>{t("user_agreement.section105")}</h2>
                    <ul>
                        <li>{t("user_agreement.section106")}</li>
                        <li>{t("user_agreement.section107")}</li>
                        <li>{t("user_agreement.section108")}</li>
                        <li>{t("user_agreement.section109")}</li>
                        <li>{t("user_agreement.section110")}</li>
                        <li>{t("user_agreement.section111")}</li>
                    </ul>
                </ul>
            </ol>
        </div >
    );
};

export default Terms_and_conditions;
