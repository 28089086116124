import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const SerQuestion = () => {
    const { t, i18n } = useTranslation("global");
    const [openArticle, setOpenArticle] = useState(null);

    const toggleArticle = (article) => {
        setOpenArticle(current => current === article ? null : article);
    };

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (


        <div>
            <div className="bahite whiteDbodye" dir={getDirection(i18n.language)}>
                <div className='why'>
                    <div className="padingtop">
                        <br />
                        <br />
                        <p className="warda">{t("Buying_guide.FAQs")}</p>
                    </div>
                    <br />
                </div>
                <div>
                    <div className="articleContent whiteH">
                        <button className="button-collapses" onClick={() => toggleArticle('FAQs1')} aria-expanded={openArticle === 'FAQs1'}>
                            <animated.span style={{ transform: openArticle === 'FAQs1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'FAQs1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Buying_guide.FAQs1")}</p>
                        </button>
                        {openArticle === 'FAQs1' && (
                            <animated.div style={{
                                backgroundColor: '#F4F6FA',
                                borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                            }}>
                                <p className="normalcolapsText">{t("Buying_guide.FAQs2")}</p>
                            </animated.div>
                        )}
                        {openArticle !== 'FAQs1' && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button-collapses" onClick={() => toggleArticle('subFAQs8')} aria-expanded={openArticle === 'subFAQs8'}>
                            <animated.span style={{ transform: openArticle === 'subFAQs8' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'subFAQs8' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Buying_guide.FAQs5")}</p>
                        </button>
                        {openArticle === 'subFAQs8' && (
                            <animated.div style={{
                                backgroundColor: '#F4F6FA',
                                borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                            }}>
                                <p className="normalcolapsText">{t("Buying_guide.FAQs4")}</p>
                            </animated.div>
                        )}
                        {openArticle !== 'subFAQs8' && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button-collapses" onClick={() => toggleArticle('ourSpecificationend1')} aria-expanded={openArticle === 'ourSpecificationend1'}>
                            <animated.span style={{ transform: openArticle === 'ourSpecificationend1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'ourSpecificationend1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Buying_guide.FAQs3")}</p>
                        </button>
                        {openArticle === 'ourSpecificationend1' && (
                            <animated.div style={{
                                backgroundColor: '#F4F6FA',
                                borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                            }}>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot1")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot2")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot3")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot5")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot4")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot6")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot7")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot8")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot9")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot10")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot11")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot12")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot13")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot14")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot15")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot16")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot17")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot18")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot19")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot20")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot21")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot22")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot23")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot24")}</p>
                                <p className="titlenormalcolapsText">{t("Buying_guide.tot25")}</p>
                                <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot26")}</p>
                                <div className="backgroundsnowwhite">
                                    <div className="columncontact ">
                                        <p className="titlenormalcolapsText">{t("Buying_guide.tot27")}</p>
                                        <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot28")}</p>

                                        <div className="habaa">
                                            <Link to="/SendMessage" state={{ MessagTitle: "تواصل" }}>
                                                <button className="sendbtnOne sendbtn">
                                                    {t("Buying_guide.tot29")}
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </animated.div>
                        )}
                        {openArticle !== 'ourSpecificationend1' && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button-collapses" onClick={() => toggleArticle('subFAQs10')} aria-expanded={openArticle === 'subFAQs10'}>
                            <animated.span style={{ transform: openArticle === 'subFAQs10' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'subFAQs10' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Buying_guide.FAQs7")}</p>
                        </button>
                        {openArticle === 'subFAQs10' && (
                            <animated.div style={{
                                backgroundColor: '#F4F6FA',
                                borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                            }}>
                                <p className="normalcolapsText">{t("Buying_guide.FAQs8")}</p>
                            </animated.div>
                        )}
                        {openArticle !== 'subFAQs10' && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button-collapses" onClick={() => toggleArticle('subFAQs11')} aria-expanded={openArticle === 'subFAQs11'}>
                            <animated.span style={{ transform: openArticle === 'subFAQs11' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'subFAQs11' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Buying_guide.FAQs9")}</p>
                        </button>
                        {openArticle === 'subFAQs11' && (
                            <animated.div style={{
                                backgroundColor: '#F4F6FA',
                                borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                            }}>
                                <p className="normalcolapsText">{t("Buying_guide.FAQs10")}</p>
                            </animated.div>
                        )}
                        {openArticle !== 'subFAQs11' && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button-collapses " onClick={() => toggleArticle('subFAQs12')} aria-expanded={openArticle === 'subFAQs12'}>
                            <animated.span style={{ transform: openArticle === 'subFAQs12' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'subFAQs12' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Buying_guide.FAQs11")}</p>
                        </button>
                        {openArticle === 'subFAQs12' && (
                            <animated.div style={{
                                backgroundColor: '#F4F6FA',
                                borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                            }}>
                                <p className="normalcolapsText">{t("Buying_guide.FAQs12")}</p>
                            </animated.div>
                        )}
                    </div>
                </div>
            </div>
            <div className="backgroundsnowwhite" dir={getDirection(i18n.language)}>
                <div className="columncontact">
                    <div className="snow">
                        <br />
                    </div>

                    <div className="snow">
                        <div className="centre">
                            <p className="subTitleContfak11">{t("Buying_guide.tit101")}
                            </p>
                        </div>
                        <br />
                        <div className="buttonRow">
                            <div className="widthbutton">
                                <Link to="/SendMessage" state={{ MessagTitle: "اطرح سؤالا" }}>
                                    <button className="blueNewTkhile buttoncleropfaten">
                                        {t("faq.sendyouropinion")}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SerQuestion;









