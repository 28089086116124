import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './SendMessage.css';

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SendMessage = () => {
    const { t, i18n } = useTranslation("global");
    const location = useLocation();
    const { MessagTitle } = location.state || {};

    const [name, setName] = useState("");
    const [emailOrPhone, setEmailOrPhone] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !emailOrPhone || !message) {
            setError(t("faq.requiredFields"));
            return;
        }
        const isPhone = /^05\d{8}$/.test(emailOrPhone);
        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailOrPhone);

        if (!isPhone && !isEmail) {
            setError(t("faq.invalidEmailOrPhone"));
            return;
        }

        try {
            await addDoc(collection(db, "messages"), {
                name,
                emailOrPhone,
                message,
                MessagTitle,
                timestamp: new Date()
            });
            setError("");
            setSuccess(true);
            // Reset form fields
            setName("");
            setEmailOrPhone("");
            setMessage("");
        } catch (err) {
            setError(t("faq.submitError"));
        }
    };

    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ayn1" dir={getDirection()}>
            <h1 className="ayn2">{t("faq.sendMessage")}</h1>
            {error && <p className="ayn3">{error}</p>}
            {success && (
                <div className="ayn11">
                    <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
                    <p className="ayn4">{t("faq.submitSuccess")}</p>
                </div>
            )}
            <form className="ayn5" onSubmit={handleSubmit}>
                <div className="ayn6">
                    <label className="ayn7" htmlFor="name">{t("faq.name")}</label>
                    <input
                        id="name"
                        type="text"
                        className="ayn8"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="ayn6">
                    <label className="ayn7" htmlFor="emailOrPhone">{t("faq.emailOrPhone")}</label>
                    <input
                        id="emailOrPhone"
                        type="text"
                        className="ayn8"
                        value={emailOrPhone}
                        onChange={(e) => setEmailOrPhone(e.target.value)}
                        required
                    />
                </div>
                <div className="ayn6">
    <label className="ayn7" htmlFor="message">{t("faq.message")}</label>
    <textarea
        id="message"
        className="ayn9"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
    />
</div>

                <button type="submit" className="ayn10">{t("faq.send1")}</button>
            </form>
        </div>
    );
};

export default SendMessage;
