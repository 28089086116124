import React, { useEffect, useState } from "react";
import "./Solutions.css";
import Headsol from "./Headsol.js";

const Solutions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div >
           <Headsol/>
        </div>
    );
};

export default Solutions;
