import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import styled from 'styled-components';
import "./UserInfoPage.css";
import { useTranslation } from 'react-i18next';



const UserDataTable = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  text-align: left;
`;

const TableHeader = styled.th`
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  padding: 8px;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  border-top: 1px solid #ddd;
`;

const UserInfoPage = () => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();

  const {
    hospitalbranche = [],
    idReal = [],
    cartItems = [],
    total = 0,
    service = "",
    birthDescription = "",
    roomType = "",
    id: idService_taked,
    specialConditions = [],
    bookingDetails = [],
    includedServices = [],
    sevicename = [],
    serviceDetails = [],
    monthValue
  } = location.state || {};

  const [nationalityError, setNationalityError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [convertedPhone, setConvertedPhone] = useState('');
  const [nationality, setNationality] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [MapUrl, setMapUrl] = useState(null);
  const [isEditing, setIsEditing] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [documentId, setDocumentId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState('');
  const [pregnancyMonth, setPregnancyMonth] = useState('');

  const [reference_number, setreference_number] = useState('');
  const validateNationality = () => {
    if (nationality === "") {
      setNationalityError(t("UserInfoPage.nationalityRequired"));
      return false;
    }
    setNationalityError('');
    return true;
  };
  const validatePhone = (enteredPhone) => {
    const phonePattern = /^05\d{8}$/;
    if (!phonePattern.test(enteredPhone)) {
      setPhoneError(t("UserInfoPage.invalidPhone"));
      return false;
    }
    setPhoneError('');
    return true;
  };


  const handlePhoneChange = (e) => {
    const enteredPhone = e.target.value;
    setPhone(enteredPhone);
    if (validatePhone(enteredPhone)) {
      setPhoneError('');
      const converted = `966${enteredPhone.slice(1)}`;
      setConvertedPhone(converted);
    } else {
      setPhoneError(t("UserInfoPage.invalidPhone"));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const determinePregnancyMonth = (serviceValue) => {
    switch (serviceValue) {
      case '': return 'X';
      case '5': return 1;
      case '-1': return 2;
      case '-2': return 3;
      case '-5': return 4;
      case '-7': return 5;
      case '-9': return 6;
      case '-11': return 7;
      case '-15': return 8;
      case '-17': return 9;
      default: return 'Unknown';
    }
  };

  useEffect(() => {
    setPregnancyMonth(determinePregnancyMonth(service));
  }, [service]);

  useEffect(() => {
    console.log("fuckkkkkkkkkkkkk data from previous page:", {
      cartItems, total, service, birthDescription, roomType, idService_taked,
      specialConditions, bookingDetails, includedServices, serviceDetails, sevicename
    });
  }, [location.state]);

  useEffect(() => {
    console.log("monthValue data from previous page:", {
      monthValue
    });
  }, [location.state]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
        setMapUrl(`https://www.google.com/maps?q=${latitude},${longitude}`);
      },
      (error) => console.error('Error getting location:', error.message)
    );
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const currentDate = new Date();
  const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedGregorianDate = new Intl.DateTimeFormat("en-US", optionsGregorian).format(currentDate);

  const formattedDate = `${formattedGregorianDate}`;
  const handleButtonClick = () => {
    navigate("/Pdfuser", {
      state: {
        idReal: idReal,

        id: idService_taked,
        name: userName,
        hospitalbranche: hospitalbranche,
        phone: phone,
        total: total,
        pregnancyMonth: pregnancyMonth,
        cartItems: cartItems.map(item => ({
          id: item.id || '',
          Title: item.Title || '',
          type: item.type || '',
          image1: item.image1 || '',
          quantity: 1
        })),
        reference_number: reference_number,
        nationality: nationality,
        Service: birthDescription,
        date: formattedDate,
        roomType: roomType,
        hospital: cartItems.length > 0 ? cartItems[0].Title : '',
        email: email,
        specialConditions: specialConditions || [], // Ensure these fields are passed
        bookingDetails: bookingDetails || [],
        includedServices: includedServices || [],
        sevicename: sevicename || [],
        serviceDetails: serviceDetails || [],
      }
    });
  };

  const UploadData = async () => {
    setIsLoading(true);

    // Input Validation
    if (phone.length !== 10 || !phone.startsWith("05")) {
      setPhoneError(t("UserInfoPage.invalidPhone"));
      setIsLoading(false);
      return;
    } else {
      setPhoneError('');
    }

    setUploadError('');
    if (!userName) {
      setUploadError(t("UserInfoPage.userNameRequired"));
      setIsLoading(false);
      return;
    }
    if (!validateNationality()) {
      setIsLoading(false);
      return;
    }
    if (!email) {
      setUploadError(t("UserInfoPage.emailRequired"));
      setIsLoading(false);
      return;
    }
    if (!phone) {
      setUploadError(t("UserInfoPage.phoneRequired"));
      setIsLoading(false);
      return;
    }

    // Generate Reference Number
    const generatereference_number = () => {
      const nationalEnc = nationality === "Saudi" ? "SA" : "NS";

      let refNumber = '';
      console.log("monthValue data:", { monthValue });

      if (monthValue !== null && monthValue !== undefined) {
        refNumber = `|${monthValue}|${idService_taked}|${nationalEnc}|`;

        for (let i = 0; i < 10; i++) {
          refNumber += Math.floor(Math.random() * 10).toString();
        }
      } else {
        refNumber = `|${idService_taked}_${idReal}|${nationalEnc}|`;

        for (let i = 0; i < 10; i++) {
          refNumber += Math.floor(Math.random() * 10).toString();
        }
      }

      console.log("Generated reference number:", refNumber);
      console.log("nationalEnc:", nationalEnc);
      return refNumber;
    };

    // Price Calculations
    const parsedTotal = typeof total === 'string' ? Number(total) : total;
    const servicePrice = parsedTotal * 0.75;
    const contractPrice = parsedTotal * 0.25;
    const vatServicePrice = nationality === "Saudi" ? 0 : servicePrice * 0.15;
    const vatContractPrice = contractPrice * 0.15;
    const totalWithVat = parsedTotal + vatServicePrice + vatContractPrice;

    const refNumber = generatereference_number();
    const currentDate = new Date();
    const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedGregorianDate = new Intl.DateTimeFormat(i18n.language === "ar" ? "en-US" : "en-US", optionsGregorian).format(currentDate);

    // Prepare Data
    const preparedData = {
      document_id: currentDate.toISOString().replace(/[\W_]+/g, "-"),
      date: formattedGregorianDate,
      idService_taked: idService_taked || '',
      map_url: MapUrl || '',
      email: email || '',
      name: userName || '',
      nationality: nationality || '',
      phone: convertedPhone || '',
      pregnancy_month: determinePregnancyMonth(service),
      reference_number: refNumber,
      room_type: roomType,
      total: totalWithVat || 0,
      specialConditions: specialConditions || '',
      bookingDetails: bookingDetails || '',
      includedServices: includedServices || '',
      serviceDetails: serviceDetails || '',
      cartItems: cartItems.map(item => ({
        id: item.id || '',
        Title: item.Title || '',
        type: item.type || '',
        price: item.Price || 0,
        quantity: item.naturalBirth || 0,
      })),
    };

    console.log('Prepared data for upload:', preparedData);

    try {
      const uploadedUrl = process.env.REACT_APP_UPLOADED_URL;
  
      const response = await fetch(uploadedUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              idService_taked: preparedData.idService_taked,
              document_id: preparedData.document_id,
              date: preparedData.date,
              map_url: preparedData.map_url,
              service: birthDescription,
              email: preparedData.email,
              name: preparedData.name,
              nationality: preparedData.nationality,
              phone: preparedData.phone,
              pregnancy_month: preparedData.pregnancy_month,
              reference_number: preparedData.reference_number,
              room_type: preparedData.room_type,
              total: preparedData.total,
              item_title: cartItems.map(item => item.Title).join(", "),
              item_id: cartItems.map(item => item.id).join(", "),
              item_price: cartItems.map(item => item.Price).join(", "),
              item_quantity: cartItems.map(item => item.naturalBirth).join(", "),
              item_type: cartItems.map(item => item.type).join(", "),
          }).toString(),
      });
  
      const result = await response.text();
      console.log('Response from server:', result);
  
      // Update State
      setDocumentId(preparedData.document_id);
      setreference_number(refNumber);
      setIsConfirmed(true);
  } catch (error) {
      console.error('Error uploading data:', error);
      setUploadError(t("UserInfoPage.error"));
  } finally {
      setIsEditing(false);
      setIsLoading(false);
  }
  
  };



  return (
    <div className='backgroundwhiteASS' style={{ direction: getDirection() }}>
      <div>
        <div className="bannersol" style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '1px',
          position: 'relative',
          textAlign: 'center'
        }}>
          <div className="bannerText">
            <div className='romel'>
            </div>
          </div>
        </div>



        <div className="cont">
          <h1>{t("UserInfoPage.title")}</h1>
          <fieldset>
            <label htmlFor="userName">{t("UserInfoPage.userName")}</label>
            <input
              disabled={!isEditing}
              type="text"
              id="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            <label htmlFor="nationality">{t("UserInfoPage.nationality")}</label>
            <select
              disabled={!isEditing}
              id="nationality"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
              required
            >
              <option value="">{t("UserInfoPage.nationality")}</option>
              <option value="Saudi">{t("UserInfoPage.value1")}</option>
              <option value="Non-Saudi">{t("UserInfoPage.value2")}</option>
            </select>

            {nationalityError && (
              <div className="error-message">
                <span className="error-icon">⚠️</span>
                <p className="error-text">{nationalityError}</p>
              </div>
            )}

            <label htmlFor="email">{t("UserInfoPage.email")}</label>
            <input
              disabled={!isEditing}
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="phone">{t("UserInfoPage.phone")}</label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={handlePhoneChange}
              inputMode="numeric" // 
              placeholder="05XXXXXXXX"
              required
            />
            {phoneError && (
              <div className="error-message">
                <span className="error-icon">⚠️</span>
                <p className="error-text">{phoneError}</p>
              </div>
            )}

          </fieldset>
          <p className='centre'>ملخص بيانات الخدمه</p>
          <UserDataTable>
            <thead>
              <tr style={{ direction: getDirection() }}>
                <TableHeader>{t("UserInfoPage.serviceName")}</TableHeader>
                <TableHeader>{t("UserInfoPage.pricePerItem")}</TableHeader>
                <TableHeader>{t("UserInfoPage.totalForItem")}</TableHeader>
                <TableHeader>{t("UserInfoPage.quantity")}</TableHeader>
                <TableHeader>{t("UserInfoPage.service")}</TableHeader>
              </tr>
            </thead>
            <tbody>
              {cartItems.map(item => (
                <TableRow key={item.id}>
                  <TableCell>{item.Title}</TableCell>
                  <TableCell>
                    {
                      Number.isNaN(Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))
                        ? (total - ((25 * total) / 100)).toFixed(2)
                        : (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)
                          - ((25 * (Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0))) / 100)).toFixed(2)
                    } {t("Services.Pur6")}
                  </TableCell>
                  <TableCell>
                    {
                      Number.isNaN((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))
                        ? ((total * 25) / 100).toFixed(2)
                        : (((Number((Number((item.Price - (item.Price * service / 100))) + (Number(item.pro) || 0)))) * 25) / 100).toFixed(2)
                    } {t("Services.Pur6")}
                  </TableCell>
                  <TableCell>{total.toFixed(2)} {t("Services.Pur6")}</TableCell>
                  <TableCell>{birthDescription}</TableCell>
                </TableRow>
              ))}

            </tbody>
          </UserDataTable>
          <div className="form-actions">
            {uploadError && (
              <div className="error-message">
                <span className="error-icon">⚠️</span>
                <p className="error-text">{uploadError}</p>
              </div>
            )}
            <div className="text-center">
              <button
                type="button"
                onClick={UploadData}
                className="button-yellow blueNewTkhile"
                disabled={!isEditing || isLoading} // Disable the button while loading
              >
                {isLoading ? (
                  <span className="custom-spinner">
                    <i className="fa fa-spinner fa-spin custom-spinner-icon"></i>
                    <span className="custom-spinner-text">{t("UserInfoPage.loadingText")}</span>
                  </span>
                ) : (
                  <span className="custom_spinner_text_white">{t("UserInfoPage.text")}</span>
                )}
              </button>
            </div>

            {isConfirmed && (
              <div className="text-center">
                <button type="button" className='TkhileAride' onClick={handleButtonClick}>
                  {t("UserInfoPage.text1")}
                </button>
              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default UserInfoPage;


