import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Borde from './components/Borde.js';
import './Services.css';
import { IoArrowUpCircle } from 'react-icons/io5';
import icon1Img from './components/images/preg.png';
import icon2Img from './components/images/homeIcon.png';
import icon3Img from './components/images/Analysis.png';
import icon4Img from './components/images/sexicon.png';

import { IoFilterCircleSharp } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import Slideshow from "./pages/Slideshow";
import Gorfe from './components/Gorfe.js';
import SerQuestion from './SerQuestion.js';
import { Link } from "react-router-dom";
import './components/Filters.css';
import Pill from "./Pill";

import icon5Img from './components/images/All_medicine.png';
import icon6Img from './components/images/Preventive_medicine.png';
import icon7Img from './components/images/Diagnostic_medicine.png';
import icon8Img from './components/images/Therapeutic_medicine.png';
import icon9Img from './components/images/Complementary_medicine.png';
import icon10Img from './components/images/Aesthetic_medicine.png';
import icon11Img from './components/images/Teenagers.png';
import icon12Img from './components/images/elderly.png';
import icon13Img from './components/images/children.png';
import icon14Img from './components/images/Newborn.png';
import icon15Img from './components/images/Adultes.png';

import icon16Img from './components/images/hospital.png';
import icon17Img from './components/images/Home_Services.png';
import icon18Img from './components/images/clinic.png';
import icon19Img from './components/images/Nursing_home.png';
import icon20Img from './components/images/Field_medicine.png';
import icon21Img from './components/images/Specialized_center.png';


import icon22Img from './components/images/Vaccinations.png';
import icon23Img from './components/images/Psychotherapy.png';
import icon24Img from './components/images/General_Surgery.png';
import icon25Img from './components/images/Binoculars.png';
import icon26Img from './components/images/Scouting.png';
import icon27Img from './components/images/Analysis.png';


import icon29Img from './components/images/Maternal_health.png';
import icon30Img from './components/images/Andrology.png';
import icon31Img from './components/images/Women_and_Obstetrics.png';
import icon32Img from './components/images/Fertility_medicine.png';




import icon33Img from './components/images/Skin_diseases.png';
import icon34Img from './components/images/Tumors.png';
import icon35Img from './components/images/cardiology.png';
import icon36Img from './components/images/Women_and_Obstetrics.png';
import icon37Img from './components/images/children.png';
import icon38Img from './components/images/Internal_Medicine.png';
import icon39Img from './components/images/Nouveaunes.png';












const Services = () => {
  const { t, i18n } = useTranslation("global");
  const { category } = useParams();
  const navigate = useNavigate();

  // State Variables
  const [activeFilters, setActiveFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [serviceData, setServiceData] = useState([]);
  const [originalServiceData, setOriginalServiceData] = useState([]);
  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [visibleItems, setVisibleItems] = useState(15);

  const sectionsData = [

    {
      title: "حدد التخصص",
      buttons: [
        {
          label: "الأمراض الجلدية", icon:
            < img src={icon33Img} className="category-icon" />
        },
        {
          label: "الأورام", icon:
            < img src={icon34Img} className="category-icon" />
        },
        {
          label: "طب القلب", icon:
            < img src={icon35Img} className="category-icon" />
        },
        {
          label: "النساء و التوليد", icon:
            < img src={icon36Img} className="category-icon" />
        },
        {
          label: "طب الأطفال", icon:
            < img src={icon37Img} className="category-icon" />
        },
        {
          label: "الأمراض الباطنية", icon:
            < img src={icon38Img} className="category-icon" />
        },
      ],
    }
  ]; const getDirection = () => (i18n.language === "ar" ? "rtl" : "ltr");

  // Handle Mobile Resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch Data
  useEffect(() => {
    const fetchAndFilterData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchData();
        setOriginalServiceData(data);
        setServiceData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setServiceData([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAndFilterData();
  }, []);

  const fetchData = async () => {
    const apiUrl = process.env.REACT_APP_URL7; // Ensure this is defined in `.env`
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  // Render Service Data
  const renderServiceData = () => {
    if (isLoading) {
      return (
        <div className="centrPi">
          <p className="centrPip">جاري تحميل البيانات...</p>
        </div>
      );
    }
    if (!serviceData.length) {
      return (
        <div className="centrPi">
          <p className="centrPip">لا توجد بيانات متاحة حاليًا. يرجى المحاولة لاحقًا.</p>
        </div>
      );
    }

    const visibleServiceData = serviceData.slice(0, visibleItems);

    return (
      <div>
        <div className="desktop-view">
          {visibleServiceData.map((service, index) => (
            <div className="speed2desktopr" key={index}>
              <Link to={`/DetailsAdd?id=${service.service_id}`}>
                <img className="speed4desktop" src={service.image} alt="Service Visual Representation" />
                <p className="speed45desktop">{service.title}</p >
                <p className="speed53desktop">{service.summary}</p>
              </Link>
            </div>
          ))}
        </div>
        <div className="mobile-view">
          {visibleServiceData.map((service, index) => (
            <div className="speed2" key={index}>
              <Link to={`/DetailsAdd?id=${service.service_id}`}>
                <img className="speed3" src={service.image} alt="Service Visual Representation" />
                <h5 className="speed4">{service.title}</h5>
                <p className="speed5">{service.summary}</p>
              </Link>
            </div>
          ))}
        </div>

        <br />
        <br />
        <br />
        <div className='centre'>
          {visibleItems < serviceData.length && (
            <button className="moreB" onClick={() => setVisibleItems(visibleItems + 15)}>
              المزيد
            </button>
          )}
        </div>

      </div>
    );
  };


  const categoriesLocalFilter = [
    { label: t('renderServiceData.label1'), value: "", icon: icon5Img },
    { label: t('renderServiceData.label2'), value: "وقاية", icon: icon6Img },
    { label: t('renderServiceData.label3'), value: "تشخيص", icon: icon7Img },
    { label: t('renderServiceData.label4'), value: "علاج", icon: icon8Img },
    { label: t('renderServiceData.label5'), value: "رعاية", icon: icon9Img },
    { label: t('renderServiceData.label6'), value: "تجميل", icon: icon10Img },
  ];

  return (
    <div className="servicescontainer" style={{ direction: getDirection() }}>
      {isMobile && (
        <button className="filter-btn-mobile" onClick={() => setIsMobileFilterVisible(!isMobileFilterVisible)}>
          <IoFilterCircleSharp />
        </button>
      )}

      <div className="whiteamene">
        <Borde />
        <nav className="categories-nav">
          <div className="categories-wrapper">
            {categoriesLocalFilter.map((category, index) => (
              <button
                key={index}
                className={`category-btn ${activeFilters.category === category.value ? "active" : ""}`}
                onClick={() => setActiveFilters({ ...activeFilters, category: category.value })}
              >
                <img src={category.icon} alt={`${category.label} icon`} className="category-icon" />
                {category.label}
              </button>
            ))}
          </div>
        </nav>
        <div className="main-layout">
          {!isMobile && (
            <div className="red-section desktopV">
              <RedSection
                sectionsData={sectionsData}
                originalServiceData={originalServiceData}
                setServiceData={setServiceData}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                setIsMobileFilterVisible={setIsMobileFilterVisible}
                t={t} // Pass `t` here

              />
            </div>
          )}
          <div className={`mobile-filter-panel mobileV ${isMobileFilterVisible ? "visible" : ""}`}>
            <RedSection
              sectionsData={sectionsData}
              originalServiceData={originalServiceData}
              setServiceData={setServiceData}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              setIsMobileFilterVisible={setIsMobileFilterVisible}
              t={t} // Pass `t` here

            />
            <button className="close-filter-btn" onClick={() => setIsMobileFilterVisible(false)}>
              اغلاق
            </button>
          </div>
          <div className="blue-section">
            <div className="Contdeps">
              <h1 className="TitlContw">{t("services.Children")}</h1>
              <div className="speed1">
                {isLoading ? <Pill /> : renderServiceData()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slideshow />
      <Gorfe />
      <SerQuestion />
    </div>
  );
};

export default Services;









const RedSection = ({
  sectionsData,
  originalServiceData,
  setServiceData,
  activeFilters,
  setActiveFilters,
  setIsMobileFilterVisible,
  t
}) => {
  const [expandedSections, setExpandedSections] = useState([]);
  
  const ageSection = {
    title: "حدد العمر",
    key: "ageSection", // A unique key to identify this section
    buttons: [

      {
        label: "حديثي الولادة", key: "رضيع", icon:
          <img src={icon39Img} className="category-icon" />

      },
      {
        label: "الأطفال", key: "طفل", icon:
          <img src={icon13Img} className="category-icon" />
      },
      {
        label: "المراهقين", key: "مراهق", icon:
          <img src={icon11Img} className="category-icon" />
      },
      {
        label: "البالغين", key: "بالغ", icon:
          <img src={icon15Img} className="category-icon" />
      },
      {
        label: "جنين", key: "جنين", icon:
          <img src={icon14Img} className="category-icon" />
      },
      {
        label: "كبار السن", key: "كبار السن",
        icon:
          < img src={icon12Img} className="category-icon" />
      },
    ],
  };
  const handleLocationSelect = (location) => {
    console.log(`Location selected: ${location}`);
    const filteredServices = originalServiceData.filter((service) =>
      service.filters?.includes(location)
    );
    setServiceData(filteredServices);
  };

  const serviceLocationSection = {
    title: "حدد نوع مزود الخدمة",
    key: "serviceLocationSection", // Unique key for this section
    buttons: [
      {
        label: "المستشفى", key: "في المستشفى", icon:

          < img src={icon16Img} className="category-icon" />
      },
      {
        label: "المنزل", key: "في المنزل", icon:
          < img src={icon17Img} className="category-icon" />
      },
      {
        label: "المستوصف", key: "المستوصف", icon:
          < img src={icon18Img} className="category-icon" />
      },
      {
        label: "دار رعاية", key: "دار رعاية", icon:
          < img src={icon19Img} className="category-icon" />
      },
      {
        label: "الطب الميداني", key: "الطب الميداني", icon:
          < img src={icon20Img} className="category-icon" />
      },
      {
        label: "مركز تخصصي", key: "مركز تخصصي", icon:
          < img src={icon21Img} className="category-icon" />
      },
    ],
  };
  const procedureSection = {
    title: t("renderServiceData.procedureTitle"),
    key: "procedureSection", // Unique key for this section
    buttons: [
      {
        label: t("renderServiceData.label7"), key: "التطعيمات", icon:
          <img src={icon22Img} className="category-icon" />
      },
      {
        label: t("renderServiceData.label8"), key: "العلاج النفسي", icon:
          <img src={icon23Img} className="category-icon" />
      },
      {
        label: t("renderServiceData.label9"), key: "جراحة عامة", icon:
          <img src={icon24Img} className="category-icon" />
      },
      {
        label: t("renderServiceData.label10"), key: "مناظير", icon:
          <img src={icon25Img} className="category-icon" />
      },
      {
        label: t("renderServiceData.label11"), key: "كشف", icon:
          <img src={icon26Img} className="category-icon" />
      },
      {
        label: t("renderServiceData.label12"), key: "تحاليل", icon:
          <img src={icon27Img} className="category-icon" />
      },
    ],
  };



  const problemSection = {
    title: "حدد المشكلة",
    key: "problemSection", // Unique key for this section
    buttons: [
      {
        label: "الصحة الجنسية", key: "الصحة الجنسية", icon:
          < img src={icon4Img} className="category-icon" />
      },
      {
        label: "صحة الأمومة", key: "صحة الأمومة", icon:
          < img src={icon29Img} className="category-icon" />
      },
      {
        label: "طب الذكورة", key: "طب الذكورة", icon:
          < img src={icon30Img} className="category-icon" />
      },
      {
        label: "النساء و التوليد", key: "النساء و التوليد", icon:
          < img src={icon31Img} className="category-icon" />
      },
      {
        label: "طب الخصوبة", key: "طب الخصوبة", icon:
          < img src={icon32Img} className="category-icon" />
      },
    ],
  };
  useEffect(() => {
    const filteredServices = originalServiceData.filter((service) => {
      if (!service.filters) return false;

      try {
        const serviceFilters = JSON.parse(service.filters);

        return Object.entries(activeFilters).every(([sectionKey, filterKey]) => {
          if (!filterKey) return true;
          return serviceFilters[sectionKey]?.includes(filterKey);
        });
      } catch (error) {
        console.error("Error parsing service filters:", error);
        return false;
      }
    });

    setServiceData(filteredServices);
    console.log("Filtered Services:", filteredServices);
  }, [activeFilters, originalServiceData, setServiceData]);
  const toggleExpand = (index) => {
    setExpandedSections((prevState) =>
      prevState.includes(index)
        ? prevState.filter((i) => i !== index)
        : [...prevState, index]
    );
  };

  const handleFilterClick = (sectionKey, filterKey) => {
    console.log(`Filter clicked in ${sectionKey}: ${filterKey}`);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [sectionKey]: prevFilters[sectionKey] === filterKey ? null : filterKey,
    }));
    setIsMobileFilterVisible(false);
    console.log("Active Filters after click:", activeFilters);

  };
  useEffect(() => {
    const filteredServices = originalServiceData.filter((service) => {
      if (!service.filters && !service.Specialiste2 && !service.sections) return false;

      try {
        const parsedFilters = service.filters ? JSON.parse(service.filters) : {};
        const parsedSpecialiste2 = service.Specialiste2 ? JSON.parse(service.Specialiste2) : [];
        const parsedSections = service.sections ? JSON.parse(service.sections) : [];
        const titles = parsedSpecialiste2.map((item) => item.title);
        const matchesKeywords = (keywords, content) =>
          keywords.every((word) => content.includes(word));
        return Object.entries(activeFilters).every(([sectionKey, filterKey]) => {
          if (!filterKey) return true; // Skip if no filter is active for this section

          switch (sectionKey) {
            case "category":
              return service.filters?.includes(filterKey);

            case "ageSection":
              return parsedFilters.age_group?.includes(filterKey);

            case "serviceLocationSection":
              return parsedFilters.service_location?.includes(filterKey);

            case "specialitySection":
              if (filterKey === "الأمراض الجلدية") {
                return titles.includes("التجميل-الجراحي");
              } else if (filterKey === "الأورام") {
                return (
                  titles.includes("جراحة-الأورام") ||
                  titles.includes("العلاج-الإشعاعي") ||
                  titles.includes("العلاج-الكيميائي") ||
                  titles.includes("طب-الأورام-النسائية")
                );
              } else if (filterKey === "النساء و التوليد") {
                return (
                  titles.includes("طب-التوليد") ||
                  titles.includes("طب-الخصوبة") ||
                  titles.includes("تشخيص-وعلاج-الالتهابات-النسائية") ||
                  titles.includes("طب-الأورام-النسائية")
                );
              } else if (filterKey === "طب القلب") {
                return (
                  titles.includes("أمراض-القلب") ||
                  titles.includes("إعادة-التأهيل-القلبي") ||
                  titles.includes("جراحة-القلب-والصدر")
                );
              } else if (filterKey === "الصحة الجنسية") {
                return (
                  titles.includes("علاج-العقم") ||
                  titles.includes("طب-الخصوبة") ||
                  titles.includes("تنظيم-الأسرة") ||
                  titles.includes("تشخيص-وعلاج-الالتهابات-النسائية")
                );
              }
              return false;

            case "procedureSection": {
              const keywords = {
                التطعيمات: ["لقاح", "تحصينات", "وقاية"],
                "العلاج النفسي": ["نفسي", "دعم", "تأهيل"],
                "جراحة عامة": ["جراحة"],
                مناظير: ["منظار"],
                كشفية: ["كشفية"],
                تحاليل: ["تحليل"],
              };
              return parsedSections.some((section) =>
                keywords[filterKey]?.some((word) =>
                  section.title.includes(word) ||
                  section.content.some((text) => text.includes(word))
                )
              );
            }

            case "problemSection": {
              const problemKeywords = {
                "الصحة الجنسية": ["التناسلية", "الأمراض المنقولة جنسياً"],
                "صحة الأمومة": ["الحمل", "الأمومة"],
                "طب الذكورة": ["التناسلي", "الخصوبة"],
                "النساء والتوليد": ["جنين", "ولادة", "الحمل"],
                "طب الخصوبة": ["تلقيح", "إنجاب"],
              };

              const keywords = problemKeywords[filterKey];
              if (!keywords) return false;

              return parsedSections.some((section) =>
                matchesKeywords(keywords, section.title) ||
                matchesKeywords(keywords, section.content.join(" "))
              );
            }

            default:
              return false;
          }
        });
      } catch (error) {
        console.error("Error parsing filters, sections, or Specialiste2:", error);
        return false;
      }
    });

    setServiceData(filteredServices);
    console.log("Active Filters Updated:", activeFilters);
    console.log("Filtered Services based on active filters:", filteredServices);
  }, [activeFilters, originalServiceData]);


  return (
    <div >
      <div className="red-title-section">
        <div class="red-title-section">
          <div className="titpart">
            <p>{t("renderServiceData.label18")}</p>

          </div>
          <div class="red-buttons-row">
            <Link to="/women_services" className="category-link">

              <button className="category-button">
                <span class="button-icon">
                  <img src={icon1Img} alt="Hospital Logo" className="category-icon" />
                </span>
                <p className="category-text">
               {t("renderServiceData.label15")}
                </p>
              </button>
            </Link>
            <button className="category-button"
              onClick={() => handleFilterClick("procedureSection", "تحاليل")}
            >
              <span class="button-icon">
                <img src={icon3Img} className="category-icon" />
              </span>
              {t("renderServiceData.label12")}
            </button>
            <button className="category-button"
              onClick={() => handleLocationSelect("في المنزل")}
            >
              <span class="button-icon">
                <img src={icon2Img} className="category-icon" />
              </span>
              {t("renderServiceData.label17")}
            </button>

            <button
              className="category-button"
              onClick={() => handleFilterClick("specialitySection", "الصحة الجنسية")}
            >
              <span class="button-icon">
                <img src={icon4Img} className="category-icon" />
              </span>
              {t("renderServiceData.label16")}
              </button>

          </div>
        </div>
        {sectionsData.map((section, index) => (
          <div key={index} className="red-title-section">

            <div className="titpart">
              <p>
                {section.title}
              </p>
            </div>
            <div className="red-buttons-row">
              {expandedSections.includes(index)
                ? section.buttons.map((button, i) => (
                  <button
                    key={i}
                    className={`red-button ${activeFilters.specialitySection === button.label ? "active" : ""
                      }`} // Add 'active' class if selected
                    onClick={() => handleFilterClick("specialitySection", button.label)}
                  >
                    <span className="butticons">{button.icon}</span>
                    {button.label}
                  </button>
                ))
                : section.buttons.slice(0, 4).map((button, i) => (
                  <button
                    key={i}
                    className={`red-button ${activeFilters.specialitySection === button.label ? "active" : ""
                      }`} // Add 'active' class if selected
                    onClick={() => handleFilterClick("specialitySection", button.label)}
                  >
                    <span className="butticons">{button.icon}</span>
                    {button.label}
                  </button>
                ))}
            </div>
            {section.buttons.length > 4 && (
              <button
                className="more-button"
                onClick={() => toggleExpand(index)}
              >
                {expandedSections.includes(index) ? "عرض أقل" : "عرض المزيد"}
              </button>
            )}
          </div>
        ))}

        <div className="red-title-section">
          <div className="titpart">
            <p> {procedureSection.title}</p>
          </div>
          <div className="red-buttons-row">
            {procedureSection.buttons.map((button, i) => (
              <button
                key={i}
                className={`red-button ${activeFilters.procedureSection === button.key ? "active" : ""
                  }`}
                onClick={() => handleFilterClick(procedureSection.key, button.key)}
              >
                <span className="butticons">{button.icon}</span>
                {button.label}
              </button>
            ))}
          </div>
          {procedureSection.buttons.length > 4 && (
            <button
              className="more-button"
              onClick={() => toggleExpand(2)}
            >
              {expandedSections.includes(2) ? "عرض أقل" : "عرض المزيد"}
            </button>
          )}
        </div>




      </div>





      <div className="red-title-section">
        <div className="titpart">
          <p> {serviceLocationSection.title}</p>
        </div>
        <div className="red-buttons-row">
          {serviceLocationSection.buttons.map((button, i) => (
            <button
              key={i}
              className={`red-button ${activeFilters.serviceLocationSection === button.key ? "active" : ""
                }`}
              onClick={() => handleFilterClick(serviceLocationSection.key, button.key)}
            >
              <span className="butticons">{button.icon}</span>
              {button.label}
            </button>
          ))}
        </div>
        {serviceLocationSection.buttons.length > 4 && (
          <button
            className="more-button"
            onClick={() => toggleExpand(1)}
          >
            {expandedSections.includes(1) ? "عرض أقل" : "عرض المزيد"}
          </button>
        )}
      </div>

      <div className="red-title-section">

        <div className="titpart">
          <p> {ageSection.title}</p>
        </div>
        <div className="red-buttons-row">

          {ageSection.buttons.map((button, i) => (
            <button
              key={i}
              className={`red-button ${activeFilters.ageSection === button.key ? "active" : ""
                }`} // Add 'active' class if selected
              onClick={() => handleFilterClick(ageSection.key, button.key)}
            >
              <span className="butticons">{button.icon}</span>
              {button.label}
            </button>
          ))}
        </div>
        {ageSection.buttons.length > 4 && (
          <button
            className="more-button"
            onClick={() => toggleExpand(0)}
          >
            {expandedSections.includes(0) ? "عرض أقل" : "عرض المزيد"}
          </button>
        )}
      </div>

      <div className="red-title-section ">
        <div className="titpart">
          <p> {problemSection.title}</p>
        </div>
        <div className="red-buttons-row">

          {problemSection.buttons.map((button, i) => (
            <button
              key={i}
              className={`red-button ${activeFilters.problemSection === button.key ? "active" : ""
                }`}
              onClick={() => handleFilterClick(problemSection.key, button.key)}
            >
              <span className="button-icon">{button.icon}</span>
              {button.label}
            </button>
          ))}
        </div>
        {problemSection.buttons.length > 4 && (
          <button
            className="more-button"
            onClick={() => toggleExpand(3)}
          >
            {expandedSections.includes(3) ? "عرض أقل" : "عرض المزيد"}
          </button>
        )}
      </div>


    </div>
  );

};

