import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './AuthorizationLetter.css';
import icon2Img from '../components/images/se.png';
import compsign from '../components/images/compsign.png';

import SignatureCanvas from 'react-signature-canvas';

import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AuthorizationLetter = () => {
    const [agreed, setAgreed] = useState(false);

    const [showNewButton, setShowNewButton] = useState(false); // New state to control the visibility of the button
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [parsedInfo, setParsedInfo] = useState({});


    const [userName, setUserName] = useState('');
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const [birthday, setBirthday] = useState('');

    const [idNumber, setIdNumber] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [additionalInfo, setAdditionalInfo] = useState({});
    const [additionalInfo2, setAdditionalInfo2] = useState({});
    const [error, setError] = useState(null);
    const [generalServiceLoading, setGeneralServiceLoading] = useState(true);
    const [serviceDetails, setServiceDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [missingInfo, setMissingInfo] = useState(false); // State to track missing information

    const getTitleDescription = (title) => t(`Obstetrics_and_Gynecology.${title}`);
    const getBranchDescription = (Branch) => t(`Obstetrics_and_Gynecology.${Branch}`);
    const getNaturalBirthDescription = (serviceName) => t(`Obstetrics_and_Gynecology.${serviceName}`);
    const { t, i18n } = useTranslation("global");
    const location = useLocation();
    const getDirection = () => (i18n.language === "ar" ? "rtl" : "ltr");
    const language = i18n.language === "ar" ? "Ar" : "En";
    // 













    const [signatureDate] = useState(new Date().toLocaleDateString());
    const sigPad = useRef(null); // Ref for the signature pad

    const clearSignature = () => {
        sigPad.current.clear();
        setIsSignatureEmpty(true);

    };

    const saveSignature = () => {
        if (sigPad.current.isEmpty()) {
            setIsSignatureEmpty(true);
            alert("Please provide a signature.");
        } else {
            setIsSignatureEmpty(false);
            const signatureData = sigPad.current.toDataURL();
            console.log("Signature saved:", signatureData); // Handle the signature image
        }
    };







    // 

    const conditions = [
        { id: 1, text: "هذا العرض صالح للاستخدام مرة واحدة و لحامله ولا يمكن التنازل عنه إلا بعد موافقة المنصة." },
        { id: 2, text: "يخضع هذا العرض لشروط التجارة الإلكترونية الصادر عن وزارة التجارة بالمملكة العربية السعودية." },
        { id: 3, text: "يخضع هذا العرض للشروط والأحكام العامة لمنصة لهاولك الطبية و المعلن عنها بالموقع الإلكتروني ومواقع التواصل الإجتماعي." },
        { id: 4, text: "بحسب القوانين السارية فإن المواطنين معفيين من الضريبة المضافة للخدمات الطبية و الأسعار المعروضة لا تشمل الضريبة" }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Includedeeexxx Services Data:", parsedInfo.included_services);

    }, []);
    useEffect(() => {
        console.log("Date from additionalInfo:", additionalInfo);
        console.log("Date from additionalInfo2:", additionalInfo2);
    }, [additionalInfo, additionalInfo2]);

    useEffect(() => {
        // Step 1: Get referenceNumber from URL
        const params = new URLSearchParams(location.search);
        const refNumber = params.get('referenceNumber');

        if (refNumber) {
            setReferenceNumber(refNumber);
            const parsedData = parseReferenceNumber(refNumber);

            // Check if both serviceId and hospitalId are available
            if (parsedData?.serviceId && parsedData?.hospitalId) {
                fetchAdditionalInfoByIdN(parsedData.serviceId, parsedData.hospitalId);
            }

            // Fetch based on referenceNumber if date is missing
            if (!additionalInfo.date) {
                fetchAdditionalInfoByReference(refNumber);
            }
        }
    }, [location, additionalInfo.date]);

    useEffect(() => {
        // Step 1: Get referenceNumber from URL
        const params = new URLSearchParams(location.search);
        const refNumber = params.get('referenceNumber');

        if (refNumber) {
            setReferenceNumber(refNumber);
            const parsedData = parseReferenceNumber(refNumber);

            // Ensure both serviceId and hospitalId are available
            if (parsedData?.serviceId && parsedData?.hospitalId) {
                fetchAdditionalInfoByIdN(parsedData.serviceId, parsedData.hospitalId);
            }
        }
    }, [location]);


    const handleAgree = async () => {
        console.log("Service ID in handleAgree:", parsedInfo.serviceId);

        if (!birthday || !idNumber) {
            const element = document.getElementById('6'); // Replace with your actual element ID

            if (element) {
                const yOffset = -30; // Adjust offset as needed
                const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({
                    top: yPosition,
                    behavior: 'smooth'
                });
            }

            setMissingInfo(true);
            return;
        }

        setMissingInfo(false);
        setAgreed(true); // Set agreed to true to enable the "Save" button

        const formattedDate = new Date(additionalInfo.date).toISOString().split('T')[0];

        const dataToSend = {
            serviceId: parsedInfo.serviceId,
            Nationality: parsedInfo.nationality,
            Total: additionalInfo.total,
            Name: additionalInfo.name,
            referenceNumber: referenceNumber,
            customer_number: additionalInfo.customer_number,
            hospitalName: additionalInfo.hospitalName || serviceDetails?.Title, // Use hospitalName if available, otherwise fallback to Title
            date: formattedDate, // Use the formatted date
            birthday,
            idNumber
        };

        console.log("Data to be sent:", dataToSend); // Log dataToSend for debugging

        try {
            const baseUrl = process.env.REACT_APP_AUTHORIZATION_LETTER_DATA_URL;

            const response = await fetch(baseUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
            });

            // Parse the JSON response
            const result = await response.json();

            if (response.ok) {
                if (result.success) {
                    console.log("Data uploaded successfully");
                    // Optionally, display a success message here
                } else {
                    console.error("Failed to upload data:", result.error);
                }
            } else {
                console.error("Failed to upload data:", result);
                // Handle HTTP-level error
            }
        } catch (error) {
            console.error("Error while uploading data:", error);
        }

    };




    const generatePDF = async () => {
        const mainContent = document.getElementById('invoice'); // First page content
        const secondPageContent = document.getElementById('secpage'); // Second page content

        try {
            // Set fixed width for desktop-like PDF output
            const fixedWidth = 800;

            // Capture the main content for the first page
            const mainCanvas = await html2canvas(mainContent, {
                scale: 1,
                useCORS: true,
                width: fixedWidth,
                height: mainContent.scrollHeight,
                windowWidth: fixedWidth,
            });

            // Calculate PDF width and height for the first page
            const pdfWidth = fixedWidth * 0.75;
            const pdfHeight = (mainCanvas.height / mainCanvas.width) * pdfWidth;

            // Create a new PDF document
            const pdf = new jsPDF('portrait', 'pt', [pdfWidth, pdfHeight]);

            // Add main content to the first page
            const mainImgData = mainCanvas.toDataURL('image/png', 1.0);
            pdf.addImage(mainImgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

            pdf.addPage();

            const secondCanvas = await html2canvas(secondPageContent, {
                scale: 1,
                useCORS: true,
                width: fixedWidth,
                height: secondPageContent.scrollHeight,
                windowWidth: fixedWidth,
            });

            // Calculate height for the second page based on content
            const secondPageHeight = (secondCanvas.height / secondCanvas.width) * pdfWidth;

            const secondImgData = secondCanvas.toDataURL('image/png', 1.0);
            pdf.addImage(secondImgData, 'PNG', 0, 0, pdfWidth, secondPageHeight);

            pdf.save('Authorization_Letter_with_Second_Page.pdf');
        } catch (error) {
            console.error("Error generating PDF:", error);
        }

        setLoadingPdf(false); // Reset the loading state
    };



    const parseReferenceNumber = (refNumber) => {
        const parts = refNumber.split('|').filter(Boolean); // Split and remove empty entries

        if (parts.length === 3) {
            // General Service format: |serviceId_hospitalId|nationality|randomNumber
            let [serviceIdWithHospital, nationality, randomNumber] = parts;

            // Split `serviceIdWithHospital` to get both the service and hospital IDs
            const [serviceId, hospitalId] = serviceIdWithHospital.split('_');

            setParsedInfo({
                type: 'General Service',
                serviceId,
                hospitalId,
                nationality: nationality === 'SA' ? 'Saudi' : 'Non-Saudi',
                randomNumber,
            });

            console.log("Parsed Service ID:", serviceId);
            console.log("Parsed Hospital ID:", hospitalId);

            // Fetch additional information with both serviceId and hospitalId
            fetchAdditionalInfoByIdN(serviceId, hospitalId);

        } else if (parts.length === 4) {
            // Pregnancy Service format: |month|service_id|nationality|random_number
            const [month, serviceId, nationality, randomNumber] = parts;

            setParsedInfo({
                type: 'Pregnancy Service',
                month,
                serviceId,
                nationality: nationality === 'SA' ? 'Saudi' : 'Non-Saudi',
                randomNumber,
            });

            console.log("Parsed Pregnancy Service ID:", serviceId);
            console.log("Month:", month);

            // Load service details from translation file based on language and serviceId
            import(`../translation/${language}/Call.json`)
                .then((module) => {
                    const serviceData = module.default.find(service => service.id === serviceId);
                    if (serviceData) {
                        setServiceDetails(serviceData);
                    } else {
                        setError('Service details not found.');
                    }
                })
                .catch((err) => {
                    setError('Failed to load service details.');
                    console.error(err);
                });

        } else {
            setError('Invalid reference number format.');
        }
    };

    // for pregency
    const fetchAdditionalInfoByReference = async (refNumber) => {
        try {
            const baseUrl = process.env.REACT_APP_AUTHORIZATION_LETTER_URL;
            const url = `${baseUrl}?referenceNumber=${refNumber}`;

            console.log("Fetching additional info by reference number:", refNumber);

            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            });

            const data = await response.json();

            if (data.error) {
                console.error("Error fetching data by reference number:", data.error);
                setError(data.error);
            } else {
                console.log("Fetched data by reference number:", data);
                setAdditionalInfo((prevInfo) => ({
                    ...prevInfo,
                    ...data,
                }));
            }
        } catch (error) {
            console.error("Failed to fetch additional info by reference number:", error);
            setError('Failed to fetch additional information by reference number.');
        }
    };



    const fetchAdditionalInfoByIdN = async (serviceId, hospitalId) => {
        try {
            const baseUrl = process.env.REACT_APP_AUTHORIZATION_LETTER_IDN_URL;
            const url = `${baseUrl}?serviceId=${serviceId}&hospitalId=${hospitalId}`;

            console.log("Starting fetch for additional info with serviceId and hospitalId:", serviceId, hospitalId);

            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }

            const dataIDN = await response.json();
            console.log("Fetched dataIDN:", dataIDN);

            // Parse JSON strings for services, special_conditions, and included_services
            const parsedServices = dataIDN.services ? JSON.parse(dataIDN.services) : null;
            const parsedSpecialConditions = dataIDN.special_conditions ? JSON.parse(dataIDN.special_conditions) : null;
            const parsedIncludedServices = dataIDN.included_services ? JSON.parse(dataIDN.included_services) : null;

            // Update parsedInfo with parsed data
            setParsedInfo((prevInfo) => ({
                ...prevInfo,
                services: parsedServices,
                special_conditions: parsedSpecialConditions,
                included_services: parsedIncludedServices,
            }));

            return dataIDN;

        } catch (error) {
            console.error("Failed to fetch additional info:", error);
            setError("Failed to retrieve additional information. Please try again.");
            return null;
        }
    };




    const isFormComplete = userName && !isSignatureEmpty && birthday && idNumber;

    return (
        <div className="auth-container" style={{ direction: getDirection() }}>

            <div
                style={{
                    textAlign: 'center',
                    direction: getDirection(),
                    backgroundColor: '#ffffff',
                }}
                className="invoiceA"
                id="invoice"
            >                <header className="invoice-header" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>


                    <div style={{ textAlign: 'right' }}>
                        <p className="headerpdfT">منصة لها ولك الطبية </p>
                        <p className="headerpdf">رقـــــــــم معروف 214657</p>
                        <p className="headerpdf">عـــــلامة تجارية 1443017765</p>
                        <p className="headerpdf"> السجل التجاري 4030402711</p>
                        <p className="headerpdf">الرقم الضريبي 300095977700003</p>
                    </div>
                    <img
                        src="images/loba2.png"
                        alt="Description of the image"
                        style={{ height: 'auto', width: '50px' }}
                    />
                    <div style={{ textAlign: 'left' }}>
                        <p className="headerpdfT">LahaLak medical platform</p>
                        <p className="headerpdf">Maroof no :214657</p>
                        <p className="headerpdf">T.M 1443017765</p>
                        <p className="headerpdf">C.R. 4030402711</p>
                        <p className="headerpdf">Tax reg 300095977700003 </p>

                    </div>
                </header>
                {referenceNumber && (
                    <div className="user-details" dir={getDirection(i18n.language)}>
                        <h1>{t("pdfuser.letter")}</h1>
                        <div className="palambido" dir={getDirection(i18n.language)}>
                            <div className="samirainfoItem">
                                <p className="label">{t("pdfuser.Reference")}</p>
                                <p className="value">{referenceNumber}</p>
                            </div>
                            <div className="samirainfoItem">
                                <p className="label">{t("pdfuser.Date")}</p>
                                <p className="value">{additionalInfo.date}
                                </p>
                            </div>
                        </div>

                    </div>

                )}
                {error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                ) : (
                    <>

                        <Khadamethamel
                            parsedInfo={parsedInfo}
                            additionalInfo={additionalInfo}
                            serviceDetails={serviceDetails}
                            t={t}
                            getTitleDescription={getTitleDescription}
                            i18n={i18n}
                            birthday={birthday}
                            setBirthday={setBirthday}
                            idNumber={idNumber}
                            setIdNumber={setIdNumber}
                        />

                        {/* khadamet_Amme */}


                        <KhadametAmme
                            additionalInfo={additionalInfo}
                            setAdditionalInfo={setAdditionalInfo}
                            t={t}
                            i18n={i18n}
                            icon2Img={icon2Img}
                            conditions={conditions}
                            refNumber={referenceNumber}
                            birthday={birthday} // Pass birthday
                            setBirthday={setBirthday} // Pass setBirthday
                            idNumber={idNumber} // Pass idNumber
                            setIdNumber={setIdNumber} // Pass setIdNumber
                            parsedInfo={parsedInfo}
                            serviceDetails={serviceDetails}
                            getTitleDescription={getTitleDescription}
                        />

                    </>
                )}
            </div>
            <div
                id="secpage"
                style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    marginTop: '20px',
                    padding: '20px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9',
                    fontFamily: 'Arial, sans-serif',
                }}
            >
                <h2 style={{ fontSize: '1.5em', color: '#333', marginBottom: '10px' }}>معلومات الاتصال</h2>
                <p style={{ fontSize: '1em', color: '#666', marginBottom: '20px' }}>للاستفسارات أو المساعدة، يرجى التواصل معنا:</p>

                <div style={{ lineHeight: '1.8', color: '#444' }}>
                    <p style={{ margin: '5px 0' }}>للخدمات التخصصية لمشغلي لها ولك</p>
                    <p style={{ margin: '5px 0' }}>العنوان: شارع البابطين، 15366، مبنى المربع، الرياض، السعودية</p>
                    <p style={{ margin: '5px 0' }}>العنوان الثاني: حي الرحاب - شارع فلسطين، جدة، 6286</p>
                    <p style={{ margin: '5px 0' }}>الطابق الرابع - جدة</p>
                    <p style={{ margin: '5px 0' }}>هاتف: 0096612\6140488</p>
                    <p style={{ margin: '5px 0' }}>البريد الإلكتروني: <a href="mailto:info@lahalak.sa" style={{ color: '#007bff', textDecoration: 'none' }}>info@lahalak.sa</a></p>
                </div>
                <img src={compsign} alt="Icon" className="signature-iconsa" />

                {/* "Signed by User" Section */}
                <div className="signature-section">
                    {/* User's Name Input */}
                    <label className="input-wrapper">
                        <span>اسم الموقع:</span>
                        <input
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="أدخل اسمك"
                        />
                    </label>

                    <div className="date-display">
                        <span>التاريخ:</span>
                        <span>{signatureDate}</span>
                    </div>

                    <div className="signature-pad">
                        <SignatureCanvas
                            ref={sigPad}
                            penColor="black"
                            canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
                        />
                    </div>


                </div>
            </div>
            <div className="button-group">
                <button onClick={clearSignature}>مسح التوقيع</button>
                <button onClick={saveSignature}>حفظ التوقيع</button>
            </div>

            <div>
                {!agreed ? (
                    <button
                        onClick={() => {
                            if (isFormComplete) {
                                handleAgree();
                                setShowErrorMessage(false);
                            } else {
                                setShowErrorMessage(true);
                            }
                        }}
                        className="button-download buttonwhite"
                    >
                        <span>{t("pdfuser.agree")}</span>
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            setLoadingPdf(true); // Set loading state to true before generating PDF
                            generatePDF();
                        }}
                        className="button-download buttonwhite"
                    >
                        {loadingPdf ? (
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className="fa fa-spinner fa-spin"></i>
                                {t("pdfuser.generatingQuotation")}
                            </span>
                        ) : (
                            <span>{t("pdfuser.saveQuot")}</span>
                        )}
                    </button>
                )}

                {showErrorMessage && (
                    <p style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {t("pdfuser.enterAllFieldsMessage")}
                        <span className="error-icon" style={{ marginLeft: '5px' }}>⚠️</span>
                    </p>
                )}
            </div>
        </div>
    );
};


export default AuthorizationLetter;















function Khadamethamel({
    parsedInfo,
    additionalInfo,
    serviceDetails,
    t,
    getTitleDescription,
    i18n,
    birthday,       // Accept birthday
    setBirthday,    // Accept setBirthday
    idNumber,       // Accept idNumber
    setIdNumber     // Accept setIdNumber
}) {


    return (
        <div>
            {parsedInfo.type === 'Pregnancy Service' && (
                <div className="hamelkhadamet">

                    {additionalInfo.customer_number && (
                        <div >
                            <div className='whiteB' >
                                <p>{t("pdfuser.DearHospital")} {getTitleDescription(serviceDetails?.Title)}</p>
                                <p>
                                    نفيدكم بتحويل المكرم / المستفيد ، نرجو تقديم الخدمة الطبية وفق البيانات والتفاصيل التالية :
                                </p>
                            </div>
                            <br />   <br />
                            <div className='titlecollapseeB'>
                                <div className='Bluediv'>
                                    <p className='titlecollapseeWak'>
                                        {t("pdfuser.data")}
                                    </p>
                                </div>
                            </div>
                            <div className='det_graut'>
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.name")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.name}</span>
                                </div>



                                {/* <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.Total")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.total}&nbsp; {t("Services.Pur6")}</span>
                                </div> */}

                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.phone")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.customer_number}</span>
                                </div>
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.nationality")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>
                                        {t(`Obstetrics_and_Gynecology.${parsedInfo.nationality}`)}
                                    </span>
                                </div>

                                <div className='grifcellule ' >
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.Service")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.service}</span>
                                </div>
                                {/* <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.Service")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.encrypted}</span>
                                </div> */}
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("UserInfoPage.dob")}</p>
                                    <input id="6"
                                        type="date"
                                        className='sostext'
                                        value={birthday}
                                        onChange={(e) => setBirthday(e.target.value)} // Update birthday state
                                        style={{ display: 'inline' }}
                                    />
                                </div>
                                <div className="responsive-divider"></div>

                                {/* ID Number Input */}
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("UserInfoPage.ID")}:</p>
                                    <input
                                        type="text"
                                        className='sostext'
                                        value={idNumber}
                                        onChange={(e) => setIdNumber(e.target.value)} // Update idNumber state
                                        style={{ display: 'inline' }}
                                        placeholder={t("UserInfoPage.enterID")}
                                    />
                                </div>
                            </div>

                        </div>
                    )}


                    {serviceDetails ? (
                        <div className="service-details">
                            <div className="fetched-data">
                                <div className='contcoffee'>

                                    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        {serviceDetails.IncludedServices && serviceDetails.IncludedServices.map((service, index) => (
                                            <div key={index}>
                                                <br />
                                                <div className='titlecollapseeB'>
                                                    <div className='Bluediv'>
                                                        <p className='titlecollapseeWak'>{service.title}</p>
                                                    </div>
                                                </div>
                                                <br />
                                                <ul>
                                                    {service.description.map((desc, idx) => (
                                                        <li key={idx} className="totire">
                                                            <img src={icon2Img} alt="Icon" />
                                                            <span>{desc}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                    <br />

                                    <div className='Bluediv'>
                                        <p className='titlecollapseeWak'>{t("Details.addedValues")}</p>
                                    </div>
                                    <br />
                                    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        {serviceDetails.AddedValues && serviceDetails.AddedValues.map((value, index) => (
                                            <div className='titlecollapseeB' key={index}>
                                                <ul>
                                                    <li className="totire">
                                                        <img src={icon2Img} alt="Icon" />
                                                        <span>{value}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                    <br />

                                    <div className='Bluediv'>
                                        <p className='titlecollapseeWak'>{t("Details.specialConditions")}</p>
                                    </div>
                                    <br />
                                    <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        <ul style={{ padding: 0 }}>
                                            {serviceDetails.SpecialConditions && serviceDetails.SpecialConditions.map((condition, index) => (
                                                <li className="safamak" key={index}>
                                                    <span>{condition}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <br />

                                    <div className='Bluediv'>
                                        <p className='titlecollapseeWak'>{t("Details.generalConditions")}</p>
                                    </div>
                                    <br />
                                    <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        <ul style={{ padding: 0 }}>
                                            {serviceDetails.GeneralConditions && serviceDetails.GeneralConditions.map((condition, index) => (
                                                <li className="safamak" key={index}>
                                                    <span>{condition}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>Loading service details...</p>
                    )}


                </div>
            )}
        </div>
    )
}



















function KhadametAmme({
    parsedInfo,
    additionalInfo,
    setAdditionalInfo,
    t,
    i18n,
    icon2Img,
    conditions,
    refNumber,
    birthday,
    setBirthday,
    idNumber,
    setIdNumber
}) {

    const [additionalInfo2, setAdditionalInfo2] = useState({});
    const fetchAdditionalInfo = async (refNumber) => {
        try {
            const baseUrl = process.env.REACT_APP_AUTHORIZATION_LETTER_GET_URL;
            const url = `${baseUrl}?referenceNumber=${refNumber}`;
    
            console.log("Fetching fallback info with reference number:", refNumber);
    
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            });
    
            const data = await response.json();
            console.log("Data from fallback endpoint:", data);
    
            if (data.error) {
                console.error("Error fetching fallback data:", data.error);
            } else {
                setAdditionalInfo2(data);
            }
        } catch (error) {
            console.error("Failed to fetch fallback data:", error);
        }
    };
    
    // Fetch fallback data only if `additionalInfo` is missing critical fields
    useEffect(() => {
        if (!additionalInfo?.special_conditions && refNumber) {
            fetchAdditionalInfo(refNumber);
        }
    }, [additionalInfo, refNumber]);

    return (
        <>
            {parsedInfo.type === 'General Service' && (
                <div className="khadamet_Amme">
                    {(additionalInfo.customer_number || additionalInfo2.customer_number) && (
                        <div>
                            <div className='whiteB' >
                                <p>{t("pdfuser.DearHospital")}: {additionalInfo.hospitalName || additionalInfo2.hospitalName}</p>
                                <p>
                                    نفيدكم بتحويل المكرم / المستفيد ، نرجو تقديم الخدمة الطبية وفق البيانات والتفاصيل التالية :
                                </p>
                            </div>
                            <br />   <br />

                            <div className='titlecollapseeB'>
                                <div className='Bluediv'>
                                    <p className='titlecollapseeWak'>{t("pdfuser.data")}</p>
                                </div>
                            </div>
                            <div className='det_graut'>
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.phone")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>
                                        {additionalInfo.customer_number || additionalInfo2.customer_number}
                                    </span>
                                </div>
                                {/* <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.Total")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>
                                        {additionalInfo.total || additionalInfo2.total}&nbsp; {t("Services.Pur6")}
                                    </span>
                                </div> */}
                                <div className='grifcellule '>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.name")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>
                                        {additionalInfo.name || additionalInfo2.name}
                                    </span>
                                </div>
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.nationality")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>
                                        {t(`Obstetrics_and_Gynecology.${parsedInfo.nationality}`)}
                                    </span>
                                </div>
                                <div className='grifcellule '>
                                    <p className='sostitel ' style={{ display: 'inline' }}>{t("pdfuser.Service")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>
                                        {additionalInfo.service || additionalInfo2.service}
                                    </span>
                                </div>

                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("UserInfoPage.dob")}</p>
                                    <input id="6"
                                        type="date"
                                        className='sostext'
                                        value={birthday}
                                        onChange={(e) => setBirthday(e.target.value)} // Update birthday state
                                        style={{ display: 'inline' }}
                                        placeholder={t("UserInfoPage.enterdob")}

                                    />
                                </div>
                                <div className="responsive-divider"></div>
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("UserInfoPage.ID")}:</p>
                                    <input
                                        type="text"
                                        className='sostext'
                                        value={idNumber}
                                        onChange={(e) => setIdNumber(e.target.value)} // Update idNumber state
                                        style={{ display: 'inline' }}
                                        placeholder={t("UserInfoPage.enterID")}

                                    />
                                </div>
                            </div>
                            <br />
                            <br />
                            {/* Special Conditions */}
                            <div className='contcoffee'>



                                <div className="fetched-data">
                                    <br />
                                    <br />
                                    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        <div className='titlecollapseeB'>
                                            <div className='Bluediv'>
                                                <p className='titlecollapseeWak'>{t("Details.includedServices")}</p>
                                            </div>
                                        </div>
                                        <ul>
                                            {Array.isArray(parsedInfo.included_services?.content) && parsedInfo.included_services.content.length > 0 ? (
                                                parsedInfo.included_services.content.map((item, index) => (
                                                    <li key={`included-service-${index}`} className="totire">
                                                        <img src={icon2Img} alt="Icon" />
                                                        <span>{item.trim()}</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No included services available.</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <br />
                                <br />
                                {parsedInfo.services?.bookingDetails?.length > 0 && (
                                    <div className="booking-details-section">
                                        <div className="titlecollapseeB">
                                            <div className="Bluediv">
                                                <p className="titlecollapseeWak">{t("Details.bookingDetails")}</p>
                                            </div>
                                        </div>
                                        <ul>
                                            {parsedInfo.services.bookingDetails.map((detail, index) => (
                                                <li key={`booking-detail-${index}`} >

                                                    <span>
                                                        {Object.entries(detail).map(([key, value]) => (
                                                            <li key={`${key}-${index}`} className="totire">
                                                                <img src={icon2Img} alt="Icon" />
                                                                {value}
                                                            </li>
                                                        ))}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <br />
                                <br />
                                <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                    <div className='titlecollapseeB'>
                                        <div className='Bluediv'>
                                            <p className='titlecollapseeWak'>{t("Details.specialConditions")}</p>
                                        </div>
                                    </div>
                                    <ul>
                                        {Array.isArray(parsedInfo.special_conditions?.content) && parsedInfo.special_conditions.content.length > 0 ? (
                                            parsedInfo.special_conditions.content.map((item, index) => (
                                                <li key={`included-service-${index}`} className="totire">
                                                    <img src={icon2Img} alt="Icon" />
                                                    <span>{item.trim()}</span>
                                                </li>
                                            ))
                                        ) : (
                                            <li>No conditions  available.</li>
                                        )}
                                    </ul>
                                </div>
                                <br />
                                <br />






                                {/* Booking Details */}








                                {/* <div className="fetched-data">
                                    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        <div className='titlecollapseeB'>
                                            <div className='Bluediv'>
                                                <p className='titlecollapseeWak'>{t("Details.bookingDetails")}</p>
                                            </div>
                                        </div>
                                        <ul>
                                            {additionalInfo?.bookingDetails?.length > 0 ? (
                                                additionalInfo.bookingDetails.map((detail, index) => (
                                                    <li key={`booking-${index}`} className="totire">
                                                        <img src={icon2Img} alt="Icon" />
                                                        <span>{detail.description1}</span>
                                                    </li>))) : (
                                                <li>{t("Details.noBookingDetails")}</li>)
                                            }
                                        </ul>
                                    </div>
                                </div> */}
                                <br />
                                <br />
                                {/* General Conditions */}
                                <div className='Bluediv'>
                                    <p className='titlecollapseeWak'>{t("Details.generalConditions")}</p>
                                </div>
                                <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                    <ul style={{ padding: 0 }}>
                                        {conditions.map((condition) => (
                                            <li key={condition.id} className="safamak">
                                                {condition.text}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div >
            )
            }
        </>
    );
}

