import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pill from "../Pill";

const EstablishmentServicesAssistance = () => {
    const { t, i18n } = useTranslation('global');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => { 
        return i18n.language === 'ar' ? 'rtl' : 'ltr';
    };

    return (
        <div className="restricted-page">
          <h1 className="title">شبكة لها و لك الطبية</h1>
          <p className="message">انتظرونا قريبا وبامكانكم انهاء الاجراءات عبر زيارة مكاتب خدمة العملاء او التواصل معنا</p>
          <p className="contact-info">
            <strong>أرقام التواصل:</strong> 0571605516 - 0599489990
          </p>
          <p className="email-info">
            <strong>Email:</strong> info@lahalak.sa
          </p>
          <p className="address">
            <strong>جده:</strong> شارع فلسطين 6286، حي الحمراء، عمارة نورا 3738<br />
            <strong>الرياض:</strong> شارع الملك فيصل 15366، المربع، قاعه 4
          </p>
          <div className='centre'><Pill /></div>
        </div>
      );
};

export default EstablishmentServicesAssistance;
