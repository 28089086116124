import React from 'react';
import './Shop.css';
import { Link } from 'react-router-dom';

const Shop = () => {
    return (
        <div>
            <div className="header"><br />
                <div className="container">
                    <div className="row">
                        <div className="col-2"> <br />
                            <h1>Change your furniture,
                                <br />Change your Company.</h1>
                        </div>
                        <div className="col-22">
                            <img src="\assets\ecomerce.jpg" alt="" />
                        </div>
                    </div>
                    <div className="navbar">
                        <div className="logo">
                            <a href="index.html">
                                <img src=
                                    "https://lh3.googleusercontent.com/proxy/uRqPTt4ehiMEcQL4LnQh6q8sLKSLRIKjg9qC_se4l4M2fkALO3a4sXdzm5iJoB1jJgDoB0JD5jMAQJ1_sJ02LHXGRc3uHPNIrLrsB-nsa4Gli9jPsDM"
                                    alt="" width="125px" />
                            </a>
                        </div>
                        <nav className='navlist'>
                            <ul > <br />
                                <li>  <span style={{ color: 'white' }}><Link to="/Phone" className="btn">Phone</Link></span> </li>
                                <li>  <span style={{ color: 'white' }}><Link to="/controlunit" className="btn">Control_Unit</Link></span> </li>
                                <li>  <span style={{ color: 'white' }}><Link to="/cabel" className="btn">Cable</Link></span> </li>
                                <li>  <span style={{ color: 'white' }}><Link to="/liscence" className="btn">License</Link></span> </li>
                                <li>  <span style={{ color: 'white' }}><Link to="/switch" className="btn">Switch</Link></span> </li>
                                {/* <Link to="/shop" style={{ color: 'white' }}>
                                    Add A new item
                                </Link> */}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Shop;
