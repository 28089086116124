import React, { useEffect, useState, useRef } from "react";
import "./Support.css";
import { useTranslation } from 'react-i18next';
import CounterBox from './Counter';
import { useSpring, animated } from 'react-spring';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

const Support = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const [openArticle, setOpenArticle] = useState(null);
    const [openSection, setOpenSection] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const toggleSection = (section) => {
        setOpenSection(current => current === section ? null : section);
        setOpenArticle(null); // Close any open article when a section is toggled
    };

    const toggleArticle = (article) => {
        setOpenArticle(current => current === article ? null : article);
        setOpenSection(null); // Close any open section when an article is toggled
    };

    const AnimatedDiv = ({ isOpen, children }) => {
        const animationProps = useSpring({
            backgroundColor: isOpen ? '#F4F6FA' : 'transparent',
            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
        });

        return (
            <animated.div style={animationProps}>
                {children}
            </animated.div>
        );
    };

    const openModal = () => {
        const button = document.querySelector('.PlayButton');
        button.classList.toggle('active');
        setIsModalOpen(true);
    };

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
        }
        setIsPlaying(!isPlaying);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsPlaying(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/%D8%B4%D8%A8%D9%83%D8%A9%20%D9%84%D9%87%D8%A7%20%D9%88%D9%84%D9%83%20%D8%A7%D9%84%D8%B7%D8%A8%D9%8A%D8%A9.mp4?alt=media&token=70e8fd03-68b5-4314-818e-17ea4312a1be';

    return (
        <div className={getDirection() + " backgroundwhiteASS"} style={{ direction: getDirection() }}>
            <div className="bannersol">
                <video
                    ref={videoRef}
                    className="bannerVideo"
                    src={videoUrl}
                    type="video/mp4"
                    playsInline
                    loop
                    autoPlay
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                >
                    Your browser does not support the video tag.
                </video>
                <button
                    className={`PlayButton ${isPlaying ? 'active' : ''}`}
                    onClick={handlePlayPause}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        color: '#fff',
                    }}
                >
                    {isPlaying ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="50px"
                            height="50px"
                        >
                            <rect x="6" y="4" width="4" height="16"></rect>
                            <rect x="14" y="4" width="4" height="16"></rect>
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="50px"
                            height="50px"
                        >
                            <path d="M8 5v14l11-7z"></path>
                        </svg>
                    )}
                </button>

            </div>

            <div className="bahite">
                <br />
                <div>
                    <p className="GoldCont">{t("Support.Prob")}</p>
                </div>
                <br />
                <div className="backgroundwhite" dir={getDirection(i18n.language)}>
                    <p className="normalcolapsTextCaption">{t("Support.captiontit1")}</p>
                    <br />
                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle('Support.pip1_4')} aria-expanded={openArticle === 'Support.pip1_4'}>
                            <animated.span style={{ transform: openArticle === 'Support.pip1_4' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'Support.pip1_4' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Support.pip1_4")}</p>
                        </button>
                        {openArticle === 'Support.pip1_4' && (
                            <AnimatedDiv isOpen={openArticle === 'Support.pip1_4'}>
                                <p>{t("Support.pip5")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== 'Support.pip1_4' && <div className="line"></div>}
                    </div>

                    <div className="articleContent whiteH">
                        <button className="button_mada" onClick={() => toggleArticle('Support.pip1_3')} aria-expanded={openArticle === 'Support.pip1_3'}>
                            <animated.span style={{ transform: openArticle === 'Support.pip1_3' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openArticle === 'Support.pip1_3' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Support.pip1_3")}</p>
                        </button>
                        {openArticle === 'Support.pip1_3' && (
                            <AnimatedDiv isOpen={openArticle === 'Support.pip1_3'}>
                                <p>{t("Support.pip8")}</p>
                                <p className="titlenormalcolapsTextULs">{t("Support.pip9")}</p>
                                <p>{t("Support.pip10")}</p>
                                <p className="titlenormalcolapsTextULs">{t("Support.pip11")}</p>
                                <p>{t("Support.pip12")}</p>
                                <p className="titlenormalcolapsTextULs">{t("Support.pip13")}</p>
                                <p>{t("Support.pip14")}</p>
                                <p className="titlenormalcolapsTextULs">{t("Support.pip15")}</p>
                            </AnimatedDiv>
                        )}
                        {openArticle !== 'Support.pip1_3' && <div className="line"></div>}
                    </div>

                    <div>
                        <button className="button_mada" onClick={() => toggleSection('solution')} aria-expanded={openSection === 'solution'}>
                            <animated.span style={{ transform: openSection === 'solution' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openSection === 'solution' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Support.tit10")}</p>
                        </button>
                        {openSection === 'solution' && (
                            <AnimatedDiv isOpen={openSection === 'solution'}>
                                <div id="solution" className={`ContactU ${openSection === 'solution' ? 'open' : 'closed'}`}>
                                    <p className="ULS">{t("Support.tit10")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit1")}</p>
                                    <p>{t("Support.tit2")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit3")}</p>
                                    <p>{t("Support.tit4")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit5")}</p>
                                    <p>{t("Support.tit6")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit7")}</p>
                                    <p>{t("Support.tit8")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit9")}</p>
                                </div>
                            </AnimatedDiv>
                        )}
                        {openSection !== 'solution' && <div className="line"></div>}
                    </div>

                    <div>
                        <button className="button_mada" onClick={() => toggleSection('loc')} aria-expanded={openSection === 'loc'}>
                            <animated.span style={{ transform: openSection === 'loc' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openSection === 'loc' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Support.tit11")}</p>
                        </button>
                        {openSection === 'loc' && (
                            <AnimatedDiv isOpen={openSection === 'loc'}>
                                <div id="loc" className={`ContactU ${openSection === 'loc' ? 'open' : 'closed'}`}>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit12")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit13")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit14")}</p>
                                </div>
                            </AnimatedDiv>
                        )}
                        {openSection !== 'loc' && <div className="line"></div>}
                    </div>

                    <div>
                        <button className="button_mada" onClick={() => toggleSection('ourSpecificationend')} aria-expanded={openSection === 'ourSpecificationend'}>
                            <animated.span style={{ transform: openSection === 'ourSpecificationend' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openSection === 'ourSpecificationend' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Support.tit30")}</p>
                        </button>
                        {openSection === 'ourSpecificationend' && (
                            <AnimatedDiv isOpen={openSection === 'ourSpecificationend'}>
                                <div id="ourSpecificationend" className={`ContactU ${openSection === 'ourSpecificationend' ? 'open' : 'closed'}`}>
                                    <p className="normaltst">{t("Support.tit16")}</p>
                                    <p>{t("Support.tit16_1")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit17")}</p>
                                    <p>{t("Support.tit18")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit19")}</p>
                                    <p>{t("Support.tit20")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit21")}</p>
                                    <p>{t("Support.tit22")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit23")}</p>
                                    <p>{t("Support.tit24")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit25")}</p>
                                    <p>{t("Support.tit26")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit27")}</p>
                                    <p>{t("Support.tit28")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit29")}</p>
                                    <p>{t("Support.tit30")}</p>
                                    <p className="titlenormalcolapsTextULs">{t("Support.tit31")}</p>
                                </div>
                            </AnimatedDiv>
                        )}
                        {openSection !== 'ourSpecificationend' && <div className="line"></div>}
                    </div>

                    <div>
                        <button className="button_mada" onClick={() => toggleSection('service')} aria-expanded={openSection === 'service'}>
                            <animated.span style={{ transform: openSection === 'service' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                {openSection === 'service' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </animated.span>
                            <p className="textCont">{t("Support.tit10_2")}</p>
                        </button>
                        {openSection === 'service' && (
                            <AnimatedDiv isOpen={openSection === 'service'}>
                                <div id="service" className={`ContactU ${openSection === 'service' ? 'open' : 'closed'}`}>
                                    <CounterBox />
                                </div> 
                            </AnimatedDiv>
                        )}
                        {openSection !== 'service' && <div className="line"></div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
