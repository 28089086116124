import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './DetailsAddOnServices.css';
import { useTranslation } from 'react-i18next';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, ContentCopy as ContentCopyIcon } from '@mui/icons-material'; // Import the Copy Icon
import { useSpring, animated } from 'react-spring';
import { Share as ShareIcon } from '@mui/icons-material'; // Import the Share Icon

const DetailsAddOnServices = () => {  
    const { t, i18n } = useTranslation("global");
    const [additionalServicesData, setAdditionalServicesData] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const location = useLocation();
    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";

    // Function to get query params from the URL
    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    // Get the id from the query string
    const id = getQueryParam('id');
    const [openSection, setOpenSection] = useState(null);

    // Copy link function
    const copyLinkToClipboard = () => {
        const link = window.location.href; // Get current URL
        navigator.clipboard.writeText(link) // Copy to clipboard
            .then(() => {
                alert("Link copied to clipboard!");
            })
            .catch(err => {
                console.error('Error copying link: ', err);
            });
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);

                const data = i18n.language === "ar"
                    ? await import('../translation/Ar/Add_on_services.json')
                    : await import('../translation/En/Add_on_services.json'); 

                console.log('Loaded additional services data:', data.default); // Debugging step
                setAdditionalServicesData(data.default);
                setLoading(false);
            } catch (error) {
                console.error('Error loading additional services data:', error);
                setLoading(false); // Ensure loading stops even on error
            }
        };
        loadData();
    }, [i18n.language]);

    const toggleSection = (index) => {
        setOpenSection(prev => prev === index ? null : index);
    };

    const renderContent = (content) => {
        if (Array.isArray(content) && content.length > 0) {
            if (typeof content[0] === 'object' && content[0] !== null) {
                return (
                    <ul>
                        {content.map((item, idx) => (
                            <li key={idx}>
                                {item.icon && <i className={`fa ${item.icon}`} aria-hidden="true"></i>} {item.text}
                            </li>
                        ))}
                    </ul>
                );
            } else {
                return (
                    <ul>
                        {content.map((item, idx) => <li key={idx}>{item}</li>)}
                    </ul>
                );
            }
        }
        return <p>{content}</p>;
    };

    // If still loading, show "Loading..."
    if (loading) {
        return <p>Loading...</p>;
    }

    // Find the service based on the ID
    const service = additionalServicesData.find(item => String(item.id) === String(id));

    if (!service) {
        console.log('Service not found for id:', id); // Debugging step
        return <p>Service not found.</p>; 
    }

    console.log('Found service:', service); // Debugging step

    return (
        <div className='backgroundblue'>
            <div className={`${getDirection()} backgroundwhitelana`} style={{ direction: getDirection() }}>
                
                {/* Banner section */}
                <div className="bannersollana" style={{
                    backgroundImage: `url(${service.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '300px',
                    position: 'relative',
                    textAlign: 'center'
                }}>
                    {/* Copy Link Button */}
                    <button
                        onClick={copyLinkToClipboard}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            backgroundColor: '#0056B3',
                            border: 'none',
                            borderRadius: '50%',
                            color: 'white',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    >
            <ShareIcon style={{ color: 'white' }} />
            </button>
                </div>

                <div className="detailsContent" dir={getDirection(i18n.language)}>
                    <p className="TitlContFatenlana">{service.title}</p>

                    <p className="summaryText">{service.description}</p>
                    {service.subsummary && <p className='subsummary'>{service.subsummary}</p>}

                    {service.sections.map((section, index) => (
                        <div key={index} className="section">
                            <button className="button-collapsess" onClick={() => toggleSection(index)} aria-expanded={openSection === index}>
                                <animated.span style={{ transform: openSection === index ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                    {openSection === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </animated.span>
                                <p className='titlecollaps'>{section.title}</p>
                            </button>
                            {openSection === index && (
                                <animated.div style={{
                                    backgroundColor: '#F4F6FA',
                                    borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)',
                                    transition: 'max-height 0.5s ease-in-out',
                                    overflow: 'hidden'
                                }}>
                                    <div className="sectionContent titlecollapsanouch" dir={getDirection(i18n.language)}>
                                        {renderContent(section.content)}
                                        {section.table && (
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        {section.table.headers.map((header, headerIndex) => (
                                                            <th key={headerIndex}>{header}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {section.table.data.map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {row.map((cell, cellIndex) => (
                                                                <td key={cellIndex} data-label={section.table.headers[cellIndex]}>{cell}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </animated.div>
                            )}
                        </div>
                    ))}
                    <br />
                    <div className='divhelped'>
                        <p className='TitlContFatenlanaBB'>{service.footer.callToActionTitle}</p>

                        <div className='div2helped'>
                            <p>{service.footer.callToActionDescription}</p>
                        </div> 
                    </div>
                    <div className="text-center">
                        <Link to="/Service_provider" state={{ id: service.id, image: service.image }}>
                            <button className="blueNewTkhile">عاين مزودي الخدمة</button>
                        </Link>
                    </div>
                    <br /> 
                    <br />
                </div>

            </div>
        </div>

    );
};

export default DetailsAddOnServices;
