// About.js
import React, { useState, useEffect } from 'react';
import "./About.css";

const About = () => {
     <div className=''>

        <div>
            <p>آخر المقالات</p>

        </div>
     </div>
};
export default About;
