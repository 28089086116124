import React, { useEffect, useState } from "react";
import "./Security.css";
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import back from '../components/images/prev.png';
import icon1 from '../components/images/84.png';
import icon2 from '../components/images/93.png';
import sahem from '../components/images/sahem.png';
import imagehi from '../components/images/imagehi.png';
import icon11 from '../components/images/icon11_2.png';
import icon12 from '../components/images/icon12_2.png';
import icon13 from '../components/images/icon13.png';
import icon14 from '../components/images/icon14.png';
import icon15 from '../components/images/icon15.png';
import icon16 from '../components/images/icon16.png';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

const SectionItem = ({ sectionId, title, children, isOpen, toggleSection }) => {
    const { i18n } = useTranslation("global");
    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";

    return (
        <div className={`section-item ${isOpen ? 'open' : ''}`} dir={getDirection()} style={{ backgroundColor: isOpen ? '#F4F6FA' : 'transparent' }}>
            <button onClick={() => toggleSection(sectionId)} className={`button-collapse ${getDirection()}`} aria-expanded={isOpen}>
                <animated.span style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                    <ExpandMoreIcon style={{ fontSize: 30, color: '#0056B3' }} />
                </animated.span>
                <p className="textCont">{title}</p>
            </button>
            <animated.div style={{
                backgroundColor: isOpen ? '#F4F6FA' : 'transparent',
                borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
            }}
                className="section-content">
                {children}
            </animated.div>
        </div>
    );
};

const Security = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const [openSection, setOpenSection] = useState(null);
    const toggleSection = (section) => {
        setOpenSection(current => current === section ? null : section);
    };

    const cardsData = [
        { img: icon1, title: t("security.texticon1"), text: t("security.texticon1") },
        { img: icon2, title: t("security.texticon2"), text: t("security.texticon2") },
    ];

    const ourSpecification = [
        { img: icon11, title: t("security.titleicon11"), text: t("security.texticon11") },
        { img: icon12, title: t("security.titleicon12"), text: t("security.texticon12") },
        { img: icon13, title: t("security.titleicon13"), text: t("security.texticon13") },
        { img: icon14, title: t("security.titleicon14"), text: t("security.texticon14") },
        { img: icon15, title: t("security.titleicon15"), text: t("security.texticon15") },
        { img: icon16, title: t("security.titleicon16"), text: t("security.texticon16") },
    ];

    const cardsData1 = [
        { img: sahem, title: t("security.titlesahem1"), text: t("security.textsahem1") },
        { img: sahem, title: t("security.titlesahem2"), text: t("security.titlesahem2") },
        { img: sahem, title: t("security.titlesahem3"), text: t("security.textsahem3") },
    ];

    return (
        <div className={getDirection() + " backgroundwhiteASS"} style={{ direction: getDirection() }}>
            <div className="bannersol" style={{ backgroundImage: `url(${back})` }}>
                {/* <div className="bannerText">
                    <p className="TitlCont">{t("security.hello1")}</p>
                    <p className="TitlCont">{t("security.hello2")}</p>
                </div> */}
            </div>
            <div className="bahite" dir={getDirection(i18n.language)}>
                <br />
                <div>
                    <p className="GoldCont"> {t("footer.legal_assistance")}</p>
                </div>
                <br />
                <br />
                <SectionItem
                    sectionId="problem"
                    title={t("security.Prob")}
                    isOpen={openSection === 'problem'}
                    toggleSection={toggleSection}
                >
                    <div id="problem" className={`ContactU ${openSection === 'problem' ? 'open' : 'closed'}`}>
                        <p className="normalcolapsText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.desc1")}</p>
                        <p className="subTitleBCont" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.tit1")}</p>
                        <p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.text1")}</p>
                        <div className="ikbal_container">
                            {cardsData.map((card, index) => (
                                <div key={index} className="ikbal">
                                    <div className="ikbal_img">
                                        <img src={card.img} alt="icon" />
                                    </div>
                                    <div className="ikbal_text" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>
                                        <p className="normalText">{card.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <p className="subTitleBCont" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.tit2")}</p>
                        <div className="ajwad1">
                            <div className="ajwad2">
                                <ul>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt1")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt2")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt3")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt4")}</p></li>
                                </ul>
                            </div>
                            <div className="ajwad3">
                                <img src={imagehi} alt="Description of your image" />
                            </div>
                        </div>
                    </div>
                </SectionItem>
                <SectionItem
                    sectionId="solution"
                    title={t("security.Solution")}
                    isOpen={openSection === 'solution'}
                    toggleSection={toggleSection}
                >
                    <div id="solution" className={`ContactU ${openSection === 'solution' ? 'open' : 'closed'}`}>
                        <p className="subTitleBCont" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.tit3")}</p>
                        <p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.text2")}</p>
                        <p className="subTitleBCont" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.tit4")}</p>
                        <p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.text3")}<span className="link"> {t("security.text4")}</span></p>
                        <div className="card_sahem">
                            {cardsData1.map((card, index) => (
                                <div key={index} className="cards">
                                    <div className="cards-text">
                                        <div className="cards-title">{card.title}</div>
                                        <div className="cards-body">{card.text}</div>
                                    </div>
                                    <div className="cards-img">
                                        <img src={card.img} alt="icon" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </SectionItem>
                <SectionItem
                    sectionId="service"
                    title={t("security.Service")}
                    isOpen={openSection === 'service'}
                    toggleSection={toggleSection}
                >
                    <div id="service" className={`ContactU ${openSection === 'service' ? 'open' : 'closed'}`}>
                        <div className="ajwad1">
                            <div className="ajwad2">
                                <ul>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt5")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt6")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt7")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt8")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt9")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt10")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt11")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt12")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt13")}</p></li>
                                </ul>
                            </div>
                            <div className="ajwad3">
                                <ul>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt14")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt15")}</p></li>
                                    <li><p className="normalText" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt16")}</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </SectionItem>
                <SectionItem
                    sectionId="ourSpecification"
                    title={t("security.OurSpecification")}
                    isOpen={openSection === 'ourSpecification'}
                    toggleSection={toggleSection}
                >
                    <div id="ourSpecification" className={`ContactU ${openSection === 'ourSpecification' ? 'open' : 'closed'}`}>
                        <p className="subTitleMidelCont">
                            <div className="ikbal_container">
                                {ourSpecification.map((card, index) => (
                                    <div key={index} className="ikbal">
                                        <div className="ikbal_img">
                                            <img src={card.img} alt="icon" />
                                        </div>
                                        <div className="ikbal_text" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>
                                            <p className="normalText">{card.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </p>
                    </div>
                </SectionItem>
                <SectionItem
                    sectionId="contact"
                    title={t("security.Contact")}
                    isOpen={openSection === 'contact'}
                    toggleSection={toggleSection}
                >
                    <div id="contact" className={`ContactU ${openSection === 'contact' ? 'open' : 'closed'}`}>
                        <div className="columncontact">
                            <p className="subTitleMidelCont" style={{ textAlign: getDirection() === 'rtl' ? 'right' : 'left' }}>{t("security.txt17")}</p>
                            <div className="btnwidth">
                                <button className="sendbtn" onClick={() => window.open('mailto:lahalak00@gmail.com')}>
                                    {t("security.send")}
                                </button>
                            </div>
                            <br />
                        </div>
                    </div>
                </SectionItem>
            </div>
        </div>
    );
};

export default Security;
